export default Object.freeze({
  API_URL: process.env.VUE_APP_API_URL,
  RTC_URL: process.env.VUE_APP_RTC_URL,

  AUTH: {
    login: '',
    password: ''
  },

  admin: {
    email: 'test@yandex.ru',
    id: 3,
    login: 'admin',
    password: '$2a$10$tCE3IPqx2N1xp.7JyOkqMOjVHQcYv8piOECtHZEGX229WLxlpXg/q',
    phone: '7999999999',
    roleId: 1
  },

  MSK_TIMEZOME: 3 // МСК
})

export const TeachingType = Object.freeze({
  TEACHER: 1,
  LOGOPEDIST: 2,
  PRESCHOOL_TEACHER: 3
})

export const LessonStatus = Object.freeze({
  NEW: 1,
  APPOINTED: 2,
  ACTIVE: 3,
  CLOSED: 4,
  CANCELED: 5,
  REJECTED: 6
})

export const OrderType = Object.freeze({
  EXPRESS: 1,
  SINGLE: 2,
  PERIODIC: 3,
  TRIAL: 'trial'
})

export const childrenAgeGroups = [
  { id: 1, name: 'До 5 лет' },
  { id: 2, name: '5-6 лет' },
  { id: 3, name: 'Начальная школа' },
  { id: 4, name: 'Основная школа' },
  { id: 5, name: 'Старшая школа' }
]

export const Roles = Object.freeze({
  PARENT: 1,
  STUDENT: 2,
  TEACHER: 3,
  LOGOPEDIST: 4,
  PRESCHOOL_TEACHER: 5
})

export const EmployeeRoles = Object.freeze({
  ADMINISTRATOR: 1,
  MANAGER: 2,
  METHODIST_LOGOPEDIST: 3,
  METHODIST_PRESCHOOL_TEACHER: 4,
  MANAGER_KC: 5,
  TECHNICAL_SUPPORT: 6
})

export const EmployeeNames = [
  { id: 1, name: 'Администратор' },
  { id: 2, name: 'Менеджер ОП' },
  { id: 3, name: 'Методист-логопедист' },
  { id: 4, name: 'Методист дошкольной подготовки' },
  { id: 5, name: 'Менеджер КС' },
  { id: 6, name: 'Тех.Поддержка' }
]

export const ManualStatusesCancelOrder = Object.freeze({
  ORDER_CREATION_ERROR: 11,
  STUDENT_REQUEST_TO_CANCEL_ORDER: 12,
  TEACHER_REQUEST_TO_CANCEL_ORDER: 13
})
