import Vue from 'vue'

export default {
  authorization(data) {
    return Vue.axios.post('/dashboard/auth', data)
  },

  refreshToken(token) {
    return Vue.axios.post('dashboard/auth/refresh', token)
  }
}
