<template>
  <button
    :class="[
      $style.button,
      $style[type],
      { [$style.centerText]: center },
      { [$style.disabled]: disabled }
    ]"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    type: {
      type: String,
      default: '',
      require: false
    },
    center: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.button {
  transition: border-color 0.3s ease;
  cursor: pointer;

  &.primary {
    width: 11.25rem;
    margin: 0 0 0 1rem;
    padding: 0.5rem;
    background: $light-pink;
    border: 1px solid $medium-gray;
    border-radius: 0.5rem;
    font-size: 2.25rem;
  }

  &.secondary {
    min-width: 11.25rem;
    padding: 0.75rem;
    border: 1px solid $gray;
    border-radius: 1rem;
    font-size: 1.125rem;
    text-align: center;
  }

  &.tertiary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1.5rem;
    background: $light-pink;
    border: 1px solid $medium-gray;
    border-radius: 1rem;
    font-size: 1.125rem;
  }
  &.transparent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1.5rem;
    background: transparent;
    border: 1px solid $medium-gray;
    border-radius: 1rem;
    font-size: 1.125rem;
  }
  &.table {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.25rem;
    background: $light-pink;
    border: 1px solid $medium-gray;
    border-radius: 1rem;
    font-size: 1.125rem;
  }
  &.red {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1.5rem;
    background: rgba(255, 233, 233, 1);
    border: 1px solid rgba(239, 73, 60, 1);
    border-radius: 1rem;
    font-size: 1.125rem;
    color: rgba(239, 73, 60, 1);
  }
  &.centerText {
    justify-content: center;
  }

  &.disabled {
    pointer-events: none;
    background: $gainsborough;
    cursor: default;
  }

  &:hover {
    border-color: $black;
  }
}
</style>
