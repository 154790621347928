<template>
  <modal-wrapper :title="title" @close="close">
    <div :class="$style.modal__alert">
      <i />
      <div :class="$style.modal__alertText">
        <span>{{ message }}</span>
      </div>
      <div :class="$style.modal__buttonsContainer">
        <custom-button
          :class="$style.modal__button"
          type="tertiary"
          :center="true"
          @click="close"
        >
          Нет
        </custom-button>
        <custom-button
          :class="$style.modal__button"
          type="tertiary"
          :center="true"
          @click="confirm"
        >
          Да
        </custom-button>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/basic/ModalWrapper'
import CustomButton from '@/basic/Button'

export default {
  name: 'ConfirmModal',
  components: {
    ModalWrapper,
    CustomButton
  },
  computed: {
    params() {
      return this.$store.getters['modalService']
    },
    title() {
      return this.params?.title ?? 'Title'
    },
    message() {
      return this.params?.message ?? 'Message'
    }
  },
  methods: {
    close(config = { success: true, result: false }) {
      this.$store.dispatch('closeModal', config)
    },
    confirm() {
      this.close({ success: true, result: true })
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/breakpoints.scss';

.modal {
  &__alert {
    padding: 1.25rem;
    min-width: 18.75rem;
  }

  &__alertText {
    font-weight: 400;
    text-align: center;
    margin-bottom: 1.875rem;

    span {
      white-space: pre-line;
    }
  }

  &__buttonsContainer {
    display: flex;
  }

  &__button ~ &__button {
    margin-left: 1.25rem;
  }

  &__button {
    width: 100%;
  }
}
</style>
