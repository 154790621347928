export default {
  state: {
    orders: [],
    orderDetails: {}
  },

  mutations: {
    setOrders(state, orders) {
      state.orders = orders
    },
    setOrderDetails(state, order) {
      state.orderDetails = order
    }
  },

  actions: {
    setOrders({ commit }, orders) {
      commit('setOrders', orders)
    }
  },

  getters: {
    orders(state) {
      return state.orders
    },
    orderDetails(state) {
      return state.orderDetails
    }
  }
}
