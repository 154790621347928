import Vue from 'vue'

export default {
  createEmployee({ email, phone, roleId, firstName, lastName, middleName }) {
    return Vue.axios.post('/dashboard/employee', {
      email,
      login: email,
      phone,
      roleId,
      firstName,
      lastName,
      middleName
    })
  },

  getEmployeeProfile() {
    return Vue.axios.get('/dashboard/employee')
  },

  deleteEmployee(id) {
    return Vue.axios.delete(`/dashboard/employee/${id}`)
  },

  getEmployees(filter = {}) {
    return Vue.axios.post('/dashboard/employee/list', filter)
  },

  resetEmployeePassword(id) {
    return Vue.axios.patch(`/dashboard/employee/password/${id}`)
  },

  checkEmployeePhoneOrEmail(data) {
    return Vue.axios.post('/dashboard/employee/check', data)
  }
}
