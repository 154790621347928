export default {
  state: {
    promoDetails: {}
  },
  mutations: {
    setPromoDetails(state, promos) {
      state.promoDetails = promos
    }
  },
  actions: {},
  getters: {
    promoDetails(state) {
      return state.promoDetails
    }
  }
}
