import userApi from '@/api/users'

export default {
  state: {
    elementaryTeacherDetails: {}
  },

  mutations: {
    setElementaryTeacherDetails(state, elementaryTeacherDetails) {
      state.elementaryTeacherDetails = elementaryTeacherDetails
    }
  },

  actions: {
    async getElementaryTeacher({ commit }, pedagogId) {
      try {
        const { data } = await userApi.getElementaryTeacher({
          filter: {
            id: pedagogId
          }
        })

        if (data?.success) {
          commit('setElementaryTeacherDetails', data.result.rows[0])
        }
      } catch (error) {
        console.warn(error)
      }
    }
  },

  getters: {
    elementaryTeacherDetails(state) {
      return state.elementaryTeacherDetails
    }
  }
}
