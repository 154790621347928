<template>
  <modal-wrapper :cancellable="false" type="promo">
    <button :class="$style.button" @click="close">
      <img
        src="@/assets/icons/close.svg" alt="X"/>
    </button>
    <div :class="$style.modal" >
      <div :class="$style.modal__header">
        <div :class="$style.modal__content__flex">Промокод <span :class="$style.modal__header__id">ID {{promo.id}}</span> <div :class="$style.modal__header__group"><img src="../../assets/icons/group.svg" alt="group" />{{promo.usages}}</div></div>
        <div :class="[$style.modal__content__flex, $style.modal__content__justify, $style.modal__header__badge, $style.modal__header__badge__green]" v-if="promo.active"><img src="../../assets/icons/tick-circle.svg" alt="tick"/>Активен</div>
        <div :class="[$style.modal__content__flex, $style.modal__content__justify, $style.modal__header__badge, $style.modal__header__badge__red]" v-if="!promo.active"><img src="../../assets/icons/cross-circle.svg" alt="tick"/>Не активен</div>
      </div>
      <div :class="$style.modal__content">
        <div style="padding: 16px;border-radius: 16px;background-color: rgba(245, 245, 245, 1);margin-bottom: 36px;">
          <div style="font-weight: 400;font-size: 13px;line-height: 15.23px">
            <span style="font-weight: 500;font-size: 13px;line-height: 15.23px">Создание:</span> {{promo?.createdBy?.middleName ? promo.createdBy.middleName : ''}} {{promo?.createdBy?.firstName ? promo.createdBy.firstName : ''}} ( {{getDate(promo.createdAt)}} )
          </div>
          <div style="font-weight: 400;font-size: 13px;line-height: 15.23px">
            <span style="font-weight: 500;font-size: 13px;line-height: 15.23px">Последнее изменение:</span> {{promo?.updatedBy?.middleName ? promo.updatedBy.middleName : ''}} {{promo?.updatedBy?.firstName ? promo.updatedBy.firstName : ''}} ( {{getDate(promo.updatedAt)}} )
          </div>
        </div>
        <div :class="$style.modal__content__margin">
          <p :class="$style.modal__content__p">Название</p>
          <CustomInput :class="[$style.modal__content__border, {[$style.modal__content__border__error]: errors.name}]" v-model="promo.code"/>
        </div>
        <div :class="[$style.modal__content__margin, $style.modal__content__flex, $style.modal__content__justify]">
          <div :class="$style.modal__content__double">
            <p :class="$style.modal__content__p">Действителен с</p>
            <date-picker
              v-model="start"
              valueType="format"
              :lang="lang"
              type="datetime"
              placeholer="Дата"
              :class="$style.modal__content__border"
              style="width: 100%;"
            />
            <p v-if="errors.start" style="font-size: 10px;color: red;position: absolute;">Введите значение</p>
          </div>
          <div :class="$style.modal__content__double">
            <p :class="$style.modal__content__p">Действителен до</p>
            <date-picker
              v-model="end"
              valueType="format"
              type="datetime"
              :lang="lang"
              placeholer="Дата"
              :class="$style.modal__content__border"
              style="width: 100%;"
            />
            <p v-if="errors.end" style="font-size: 10px;color: red;position: absolute;">Введите значение</p>
          </div>
        </div>
        <div :class="[$style.modal__content__margin, $style.modal__content__flex, $style.modal__content__justify]">
          <div :class="$style.modal__content__double">
            <p :class="$style.modal__content__p">Лимит</p>
            <InputNumber :maxlength="7" v-model="limit" :class="[$style.modal__content__border, $style.modal__content__border__height]"/>
          </div>
          <div :class="$style.modal__content__double">
            <p :class="$style.modal__content__p">Лимит для пользователя</p>
            <InputNumber :maxlength="7" v-model="userLimit" :class="[$style.modal__content__border, $style.modal__content__border__height]"/>
          </div>
        </div>
        <div :class="$style.modal__content__margin">
          <div :class="[$style.modal__content__margin__small, $style.modal__content__justify, $style.modal__content__flex, $style.modal__content__bottom]">
            <div :class="$style.modal__content__p" style="width: 227px;">Пакет</div>
            <div :class="$style.modal__content__p" style="width: 138px;">Размер скидки</div>
            <div :class="$style.modal__content__p" style="width: 121px;">Тип</div>
          </div>
          <div v-for="(item, index) in items" :key="index" :class="[$style.modal__content__margin__small, $style.modal__content__bottom]">
            <div :class="[$style.modal__content__justify, $style.modal__content__flex]">
              <div style="width: 227px;">
                <DefaultSelect
                  v-model="item.tariff"
                  :items="getTariffs"
                  placeholder="Выбрать тариф"
                  style="width: 227px;"
                  :newTable="true"
                  :class="[$style.modal__content__border, $style.modal__content__border__height]"
                  @input="(val) => item.tariff = val.id"
                />
              </div>
              <div style="width: 138px;">
                <InputNumber v-if="item.id === 1" :disabled="item.id !== 1" :maxlength="7" v-model="item.amount" :class="[$style.modal__content__border, $style.modal__content__border__height]"/>
              </div>
              <div :class="$style.modal__content__border__height" style="width: 73px;">
                <DefaultSelect
                  v-if="item.id === 1"
                  v-model="item.type"
                  :items="types"
                  placeholder="Тип"
                  :newTable="true"
                  :disabled="item.id !== 1"
                  style="width: 73px;"
                  :class="[$style.modal__content__border, $style.modal__content__border__height]"
                  @input="(val) => item.type = val.id"/>
              </div>
              <div :class="[$style.modal__content__flex, $style.modal__content__justify__center, $style.modal__content__cursor__pointer]" style="width: 24px;" @click="deleteItem(item)"><img src="../../assets/icons/trash.svg" alt="trash"></div>
            </div>
            <p v-if="Object.values(errors.arrayIndex).includes(item.id)" style="font-size: 10px; color: red;">Выберите и введите все значения</p>
          </div>
          <CustomButton type="transparent" @click="addItem">Добавить</CustomButton>
        </div>

        <div :class="$style.modal__content__margin">
          <p :class="$style.modal__content__p">Комментарий</p>
          <CustomTextarea :maxlength="200" :class="$style.modal__content__border" v-model="promo.description"/>
        </div>
        <div v-if="isAdmin" :class="[$style.modal__content__flex, $style.modal__content__justify]">
          <div :class="$style.modal__content__flex">
            <CustomButton type="transparent" style="margin-right: 20px;" @click="savePromo">Сохранить</CustomButton>
            <CustomButton type="transparent" @click="close">Отмена</CustomButton>
          </div>
          <CustomButton :type="promo.active ? 'red' : 'transparent'" @click="changeActive">{{ promo.active ? 'Деактивировать' : 'Активировать' }}</CustomButton>
        </div>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/basic/ModalWrapper.vue'
import InputNumber from '@/basic/InputNumber.vue'
import CustomTextarea from '@/basic/CustomTextarea.vue'
import CustomInput from '@/basic/Input.vue'
import DefaultSelect from '@/basic/DefaultSelect.vue'
import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'
import CustomButton from '@/basic/Button.vue'
import Moment from 'moment'
import 'vue2-datepicker/index.css'
import { mapGetters } from 'vuex'
import promos from '@/api/promos'

export default {
  name: 'PromoDetails',
  components: { CustomButton, DefaultSelect, CustomInput, CustomTextarea, InputNumber, ModalWrapper, DatePicker },
  data() {
    return {
      lang: ru,
      items: [],
      start: null,
      end: null,
      limit: null,
      userLimit: null,
      types: [{ id: 1, name: 'руб' }, { id: 2, name: '%' }],
      oldPromo: null,
      errors: {
        name: false,
        start: false,
        end: false,
        index: false,
        arrayIndex: []
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    getTariffs() {
      return this.$store.getters['constants']?.tariffs.filter(tariff => tariff.typeId !== 2)
    },
    moment() {
      return Moment
    },
    params() {
      return this.$store.getters['modalService']
    },
    promo() {
      return this.params?.promo
    }
  },
  watch: {
    items() {
      const selectItems = this.items.map( el => {return { id: el.tariff }})
      for (let i = 0; i < this.getTariffs.length; i++) {
        const obj1 = this.getTariffs[i]

        for (let j = 0; j < selectItems.length; j++) {
          const obj2 = selectItems[j]
          if (obj1.id === obj2.id) {
            obj1.disabled = true
            break
          }
        }
      }
    },
    start() {
      if (this.start && this.start.length > 0) {
        this.errors.start = false
      }
    },
    end() {
      if (this.end && this.end.length > 0) {
        this.errors.end = false
      }
    }
  },
  async mounted() {
    let id = 1
    this.oldPromo = (await promos.getPromo(this.promo.id)).result
    this.start = this.moment(this.promo.start).add(-3, 'H').format('YYYY-MM-DD HH:mm:ss')
    this.end = this.moment(this.promo.end).add(-3, 'H').format('YYYY-MM-DD HH:mm:ss')
    this.limit = this.promo.limit
    this.userLimit = this.promo.userLimit
    const amount = this.promo.amount ? this.promo.amount : this.promo.discount
    const type = this.promo.amount ? 1 : 2
    for (const item of this.promo.tariffs) {
      this.items.push({id: id, tariff: item.id, type: type, amount: String(amount)})
      id++
    }
  },
  methods: {
    getDate(date) {
      return this.moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    async changeActive() {
      const { data } = await promos.updatePromo({ id: this.promo.id, active: !this.promo.active })
      this.promo.active = !this.promo.active
      this.promo.updatedAt = data.updatedAt
    },
    close() {
      this.$store.dispatch('closeModal',{
        success: true,
        result: false
      })
    },
    isSameDates(newDate, oldDate) {
      return this.moment(newDate).isSame(this.moment(oldDate).add(-3, 'H').format('YYYY-MM-DD HH:mm:ss'))
    },
    async savePromo() {
      if (this.promo.code.length > 0 && this.promo.code.length < 5) {
        this.errors.name = true
        return
      } else {
        this.errors.name = false
      }
      if (!this.start || this.start.length < 1) {
        this.errors.start = true
        return
      } else {
        this.errors.start = false
      }
      if (!this.end || this.end.length < 1) {
        this.errors.end = true
        return
      } else {
        this.errors.end = false
      }
      if (this.items.length < 1) {
        return
      }
      const type = this.oldPromo.amount ? 1 : 2
      const sendData = {
        id: this.promo.id
      }
      const newType = parseInt(this.items[0].type)
      const amount = parseInt(this.items[0].amount)

      if (this.promo.description !== this.oldPromo.description) {
        sendData.description = this.promo.description
      }
      if (this.limit !== this.oldPromo.limit) {
        sendData.limit = +this.limit === 0 ? null : +this.limit
      }
      if (this.userLimit !== this.oldPromo.userLimit) {
        sendData.userLimit = +this.userLimit === 0 ? null : +this.userLimit
      }
      if (!this.isSameDates(this.start, this.oldPromo.start)) {
        sendData.start = this.start
      }
      if (!this.isSameDates(this.end, this.oldPromo.end)) {
        sendData.end = this.end
      }
      if (this.promo.code !== this.oldPromo.code) {
        sendData.code = this.promo.code
      }
      if (this.foundMatch(this.oldPromo.tariffs, this.items)) {
        sendData.tariffIds = this.items.map(e => e.tariff)
      }

      let nullItem = this.items.filter( el => !el.tariff || el.amount.length < 1 || !el.type)

      if (nullItem.length > 0) {
        this.errors.arrayIndex = nullItem.map(el => el.id)
        return
      } else {
        this.errors.arrayIndex = this.errors.arrayIndex.filter( el => el !== nullItem.map(el => el.id))
      }
      if (newType !== type) {
        if (newType === 1) {
          sendData.discount = null
          sendData.amount = parseInt([...new Set(this.items.map(e => e.amount))].join(''))
        }
        if (newType === 2) {
          sendData.amount= null
          sendData.discount = parseInt([...new Set(this.items.map(e => e.amount))].join(''))
        }
      }
      if (newType === type) {
        if (type === 1) {
          if (amount !== this.oldPromo.amount) {
            sendData.amount = amount
          }
        }
        if (type === 2) {
          if (amount !== this.oldPromo.discount) {
            sendData.discount = amount
          }
        }
      }

      try {
        const { data } = await promos.updatePromo(sendData)
        if (data.success) {
          this.close()
          window.location.reload()
        } else {
          await this.$store.dispatch('openModal', [
            'Alert',
            { title: 'Ошибка!', message: data.message }
          ])
        }
      } catch (e) {
        await this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка!', message: e.message }
        ])
      }
    },
    foundMatch(oldArray, newArray) {
      return oldArray.some(obj1 => {
        return newArray.some(obj2 => obj1.id !== obj2.tariff)
      })
    },
    deleteItem(item){
      this.items = this.items.filter(obj => obj !== item)
    },
    addItem(){
      this.items.push({id: this.items.length + 1,tariff: null, amount: this.items[0].amount, type: this.items[0].type})
    }
  }
}

</script>

<style lang="scss">
.mx-datepicker-popup {
  z-index: 9999!important;
}
.mx-input {
  border-radius: 0;
  border-color: rgba(188, 188, 188, 1);
  &:hover {
    border-color: rgba(188, 188, 188, 1);
  }
}
</style>

<style lang="scss" module>
.button {
  position: relative;
  bottom: 3.75rem;
  left: 96%;
  cursor: pointer;
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.modal {
  width: 36.375rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
  &__header {
    font-weight: 700;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    line-height: 1.172rem;
    margin-bottom: 2.25rem;
    &__badge {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 0.879rem;
      &__green {
        width: 4.5rem;
        color: rgba(97, 187, 87, 1);
      }
      &__red{
        width: 5.5rem;
        color: rgba(239, 73, 60, 1)
      }
    }
    &__id {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      background-color: rgba(245, 245, 245, 1);
      padding: 0.25rem 0.375rem;
      border-radius: 0.25rem;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 0.879rem;
    }
    &__group {
      background-color: rgba(245, 245, 245, 1);
      padding: 0.25rem 0.375rem;
      border-radius: 0.25rem;
      font-size: 0.75rem;
      font-weight: 400;
      align-items: center;
      display: flex;
      line-height: 0.879rem;
      img {
        margin-right: 4px;
      }
    }
  }
  &__content {
    &__cursor {
      &__pointer {
        cursor: pointer;
      }
    }
    &__bottom {
      border-bottom: 0.063rem solid rgba(245, 245, 245, 1);
    }
    &__double {
      width: 15.938rem;
    }
    &__border {
      border-radius: 0;
      border-color: rgba(188, 188, 188, 1);
      box-shadow:none;
      &__error {
        border-color: #f85e0f;
      }
      &__height {
        height: 2.438rem;
        display: flex;
        align-items: center;
      }
    }
    &__p {
      font-size: 0.813rem;
      font-weight: 600;
      line-height: 0.952rem;
      font-family: Roboto, sans-serif;
      color: rgba(151, 150, 150, 1);
      margin-bottom: 0.5rem;
    }
    &__margin {
      margin-bottom: 2.25rem;
      &__small {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
      }
    }
    &__flex {
      display: flex;
    }
    &__justify {
      justify-content: space-between;
      align-items: center;
      &__center {
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
