<template>
  <modal-wrapper :cancellable="false" type="comment">
    <button :class="$style.button" @click="close">
      <img src="@/assets/icons/close.svg" alt="X" />
    </button>
    <div :class="$style.modal">
      <div
        style="
          color: rgba(42, 43, 42, 1);
          font-weight: 700;
          font-size: 16px;
          line-height: 18.75px;
          margin-bottom: 36px;
        "
      >
        Занятие №{{ comment.id }}
      </div>
      <div style="display: flex; justify-content: space-between; margin-bottom: 24px">
        <div style="width: 255px">
          <p
            style="
              color: rgba(151, 150, 150, 1);
              font-weight: 500;
              font-size: 12px;
              line-height: 15.23px;
              margin-bottom: 8px;
            "
          >
            Дата
          </p>
          <div style="width: 255px; border: 1px solid rgba(188, 188, 188, 1); padding: 10px">
            {{ moment(comment.datetime).format('YYYY-MM-DD') }}
            {{ moment(comment.datetime).format('HH:mm') }}
          </div>
        </div>
        <div style="width: 255px">
          <p
            style="
              color: rgba(151, 150, 150, 1);
              font-weight: 500;
              font-size: 12px;
              line-height: 15.23px;
              margin-bottom: 8px;
            "
          >
            Родитель
          </p>
          <div style="width: 255px; border: 1px solid rgba(188, 188, 188, 1); padding: 10px">
            {{ getName(comment.customer.attributes) }}
          </div>
        </div>
      </div>
      <div style="margin-bottom: 24px">
        <p
          style="
            color: rgba(151, 150, 150, 1);
            font-weight: 500;
            font-size: 12px;
            line-height: 15.23px;
            margin-bottom: 8px;
          "
        >
          Оценка
        </p>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100px;
            height: 16px;
          "
        >
          <div
            v-for="(el, index) in stars"
            :key="index"
            style="width: 16px; height: 16px; align-items: center"
          >
            <img
              v-if="el.id <= comment.grade"
              src="@/assets/icons/star-filled.svg"
              width="16"
              height="16"
              alt="start"
            />
            <img
              v-if="el.id > comment.grade"
              src="@/assets/icons/star.svg"
              width="16"
              height="16"
              alt="start"
            />
          </div>
          <div style="min-height: 16px">{{ comment.grade }}</div>
        </div>
      </div>
      <div style="margin-bottom: 36px">
        <p
          style="
            color: rgba(151, 150, 150, 1);
            font-weight: 500;
            font-size: 12px;
            line-height: 15.23px;
            margin-bottom: 8px;
          "
        >
          Комментарий
        </p>
        <div
          style="
            border: 1px solid rgba(188, 188, 188, 1);
            padding: 10px;
            font-size: 16px;
            font-weight: 400;
            line-height: 18.75px;
            min-height: 110px;
            color: rgba(0, 0, 0, 1);
          "
        >
          {{ comment.resultComment }}
        </div>
      </div>
      <div style="display: flex">
        <custom-button type="old" @click="hide"
          >{{ comment.isHideGrade ? 'Показать' : 'Скрыть' }} оценку</custom-button
        >
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import CustomButton from '../../basic/CustomButton.vue'
import ModalWrapper from '../../basic/ModalWrapper.vue'
import Moment from 'moment'
import orderApi from '../../api/orders'
import { LessonStatus } from '@/application/constants'

export default {
  name: 'Comment',
  components: { ModalWrapper, CustomButton },
  data() {
    return {
      stars: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]
    }
  },
  computed: {
    params() {
      return this.$store.getters['modalService']
    },
    comment() {
      return this.params.comment
    },
    moment() {
      return Moment
    }
  },
  methods: {
    ...mapActions([
      'getTeacher',
      'getTeacherOrders'
    ]),
    ...mapMutations(['setTeacherDetails']),
    close() {
      this.$store.dispatch('closeModal', {
        success: true,
        result: false
      })
    },
    getName(user) {
      const middleName = user.middleName || ''
      const firstName = user.firstName || ''
      const lastName = user.lastName || ''
      const srt = `${middleName} ${firstName} ${lastName}`
      return srt.length <= 22 ? srt : `${srt} ...`
    },
    async hide() {
      await orderApi.hideRating({
        orderId: this.comment.id,
        isHideGrade: !this.comment.isHideGrade
      })
      const user = await this.getTeacher(this.comment.teacher.id)
      await this.getTeacherOrders({
          filter: {
            teacherId: this.comment.teacher.id,
            statusId: [
              LessonStatus.NEW,
              LessonStatus.APPOINTED,
              LessonStatus.ACTIVE,
              LessonStatus.CLOSED
            ]
          }
        })
      this.setTeacherDetails({ ...user })
      this.close()
    }
  }
}
</script>

<style module lang="scss">
.button {
  position: relative;
  bottom: 3.75rem;
  left: 96%;
  cursor: pointer;
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.modal {
  width: 582px;
  height: 445px;
  padding: 0 1.5rem 1.5rem 1.5rem;
  border-radius: 1rem;
}
</style>
