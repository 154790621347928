var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",class:[
    _vm.$style.button,
    _vm.$style[_vm.type],
    { [_vm.$style.flex]: _vm.flex },
    { [_vm.$style.disabled]: _vm.disabled },
    { [_vm.$style.error]: _vm.error }
  ],on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }