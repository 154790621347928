<template>
  <div :class="$style.app" id="app">
    <transition name="leftEnter">
      <SideBar v-if="authToken && $route.name !== 'Login'" :class="$style.app__nav" />
    </transition>

    <transition name="rightEnter" mode="out-in">
      <img v-if="loading" src="@/assets/icons/loader.svg" alt="loader" />
      <router-view v-else :class="$style.app__content" />
    </transition>

    <NoticeService />
    <ModalService />
  </div>
</template>

<script>
import Moment from 'moment'

import SideBar from '@/components/SideBar'
import NoticeService from '@/components/services/NoticeService.vue'
import ModalService from '@/components/services/ModalService'

import authApi from '@/api/auth'

import paginationMixin from '@/mixins/pagination.mixin'

import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    SideBar,
    NoticeService,
    ModalService
  },
  mixins: [paginationMixin],
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'authToken',
      'isReceivedChatMessage',
      'notifications',
      'notifications',
      'chats'
    ])
  },
  async mounted() {
    Moment.locale('ru')

    const token = this.$cookies.get('x-token')

    if (!token) {
      sessionStorage.removeItem('x-access-token')
      return
    }

    if (!this.authToken && token) {
      try {
        const { data } = await authApi.refreshToken({ refreshToken: token })

        if (data?.success) {
          this.$cookies.set('x-token', data.result.refreshToken, '72h')
          sessionStorage.setItem('x-access-token', data.result.accessToken)
          this.axios.defaults.headers['x-access-token'] = data.result.accessToken
          this.setAuthToken(data.result.accessToken)

          this.$socket.io.opts.extraHeaders['x-access-token'] = data.result.accessToken
          this.$socket.connect()

          try {
            this.loading = true
            await this.getEmployeeProfile()
            await this.getConstants()
            await this.getInit()
          } finally {
            this.loading = false
          }

          if (
            this.$router.currentRoute.fullPath === '/login' &&
            !this.$cookies.get('follow-path')
          )
            this.$router.push('/orders')
        }
      } catch (error) {
        console.warn(error)
        this.$cookies.remove('x-token')
        sessionStorage.removeItem('x-access-token')
      }
    }
  },
  watch: {
    authToken(token) {
      this.$socket.disconnect()
      this.$socket.io.opts.extraHeaders['x-access-token'] = token
      this.$socket.connect()
    }
  },
  sockets: {
    'server-notifications-send'(data) {
      if (data.type.id === 21) {
        if (!this.$route.path.includes('chats')) {
          this.setIsReceivedChatMessage(true)
        }
      } else {
        return
      }
    }
  },
  methods: {
    ...mapMutations([
      'setAuthToken',
      'setNotifications',
      'setIsReceivedChatMessage',
      'setChats'
    ]),
    ...mapActions(['getEmployeeProfile', 'getConstants', 'getInit'])
  }
}
</script>

<style lang="scss" module>
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-size: 16px;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.app {
  overflow: hidden;
  display: flex;
  width: 100%;
  min-height: 100vh;

  &__nav {
    width: 20%;
  }

  &__content {
    width: 80%;
    padding: 1rem;
  }
}
</style>
