import Vue from 'vue'

export default {
  async getPromos(data) {
    return (await Vue.axios.post('/dashboard/promo/list', data))
  },
  async addPromo(data) {
    return (await Vue.axios.post('/dashboard/promo', data))
  },
  async updatePromo(data) {
    // return true
    return (await Vue.axios.patch(`/dashboard/promo/${data.id}`, data))
  },
  deletePromo(id) {
    return Vue.axios.delete(`/dashboard/promo/${id}`)
  },
  async getPromo(id) {
    return (await Vue.axios.get(`/dashboard/promo/${id}`)).data
  }
}
