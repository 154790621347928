<template>
  <label
    :class="[
      $style.radio,
      { [$style.radio_disabled]: disabled },
      { [$style.radio_error]: error }
    ]"
  >
    <input
      :class="$style.radio__input"
      :type="radio ? 'radio' : 'checkbox'"
      :checked="value"
      @input="$emit('input', $event.target.checked)"
    />
    <div :class="[$style.radio__label, { [$style.radio__label_disabled]: disabled, [$style.radio__label_small]: isSmall }]" />
  </label>
</template>

<script>
export default {
  name: 'CustomRadio',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    radio: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.radio {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0.5rem;
  border-radius: 100%;
  border: 1px solid $platinum;

  &_disabled {
    pointer-events: none;
  }

  &_error {
    border: 1px solid $error !important;
  }

  &__input {
    display: none;
  }

  &__input:checked + &__label {
    &:after {
      transform: scale(1) !important;
      transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
      opacity: 1;
    }
  }

  &__label {
    position: relative;
    width: 1.563rem;
    height: 1.563rem;
    background: $white;
    border-radius: 100%;

    &_disabled {
      background: $gainsborough;
    }

    &_small {
      width: 1rem;
      height: 1rem;
      &:after {
        left: 0.15rem!important;
        top: 0.15rem!important;
      }
    }

    &:after {
      content: '';
      position: absolute;
      vertical-align: -50%;
      width: 0.75rem;
      height: 0.75rem;
      left: 0.45rem;
      top: 0.42rem;
      border-radius: 50%;
      background: $ferm-crayola;
      transform: scale(0);
      transition: all 0.2s ease;
      pointer-events: none;
      opacity: 0.08;
    }
  }
}
</style>
