import orderApi from '@/api/orders'
import initApi from '@/api/init'

export default {
  state: {},

  mutations: {
    setConstants(state, constants) {
      for (const [key, value] of Object.entries(constants)) {
        state[key] = value
      }
    },
    setInit(state, init) {
      for(const [key, value] of Object.entries(init)) {
        state[key] = value
      }
    }
  },

  actions: {
    async getConstants({ commit }) {
      try {
        const resp = await orderApi.getTypesForOrders()

        if (resp?.success) {
          commit('setConstants', resp.result)
        }
      } catch (error) {
        console.warn(error)
      }
    },
    async getInit({ commit}) {
      try {
        const { success, result } = await initApi.init()

        if (success) {
          commit('setInit', result)
        }
      } catch (error) {
        console.warn(error)
      }
    }
  },

  getters: {
    constants(state) {
      return state
    },
    init(state) {
      return state
    }
  }
}
