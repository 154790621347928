<template>
  <div
    :class="[
      $style.input__wrapper,
      $style[inputType],
      { [$style.input__wrapper_error]: error }
    ]"
  >
    <input
      v-mask="mask || nomask"
      :class="[$style.input__input, $style[inputType]]"
      :value="value"
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      autocomplete="false"
      @input="$emit('input', $event.target.value)"
      @keydown.enter="$emit('keydown-enter')"
    />
    <div :class="$style.input__name">{{ name }}</div>
  </div>
</template>

<script>
export default {
  name: 'CustomInput',
  props: {
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    inputType: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    mask: {
      type: [String, Array, Object],
      default: () => ''
    }
  },
  data() {
    return {
      nomask: {
        mask: '*'.repeat(255),
        tokens: {
          '*': { pattern: /./ }
        }
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.input {
  &__input {
    &::placeholder {
      color: #000;
    }
  }
  &__wrapper {
    position: relative;
    border: 1px solid $input-border;
    border-radius: 0.25rem;
    transition: all 0.3s ease;
    height: 34px;
    // margin-left: 30px;

    &.secondary {
      height: auto;
      margin-left: 0;
      border: 1px solid black;
      border-radius: 0;

      &:hover {
        border-color: rgb(36, 175, 255);
      }
    }

    &_error {
      border-color: $error;
    }
  }

  &__input {
    width: 100%;
    padding: 0.75rem;
    height: 34px;
    font-size: 14px;

    &.secondary {
      height: 100%;
      padding: 0.313rem;
    }
  }

  &__name {
    position: absolute;
    top: -0.5rem;
    left: 0.75rem;
    padding: 0 0.25rem;
    background: $white;
    font-size: 0.75rem;
  }
}
</style>
