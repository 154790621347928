<template>
  <div class="noticeService">
    <transition name="notice" mode="in-out">
      <component :is="notice" v-if="notice" />
    </transition>
  </div>
</template>

<script>
import ErrorNotice from '@/components/notices/ErrorNotice'
import InfoNotice from '@/components/notices/InfoNotice'

export default {
  name: 'NoticeService',
  components: {
    ErrorNotice,
    InfoNotice
  },
  computed: {
    notice() {
      return this.$store.getters.notice
    }
  }
}
</script>
