import userApi from '@/api/users'
import orderApi from '@/api/orders'

export default {
  state: {
    logopedistDetails: {},
    logopedistOrders: [],
  },

  mutations: {
    setLogopedistDetails(state, logopedistDetails) {
      state.logopedistDetails = logopedistDetails
    },
    setLogopedistOrders(state, orders) {
      state.logopedistOrders = orders
    }
  },

  actions: {
    async getLogopedist({ commit }, pedagogId) {
      try {
        const { data } = await userApi.getLogopedist({
          filter: {
            id: pedagogId
          }
        })

        if (data?.success) {
          commit('setLogopedistDetails', data.result.rows[0])
        }
      } catch (error) {
        console.warn(error)
      }
    },
    async getLogopedistOrders({ commit }, filter) {
      try {
        const { data } = await orderApi.getOrders(filter)

        if (data?.success) {
          commit('setLogopedistOrders', data.result)
        }
      } catch (error) {
        console.warn(error)
      }
    }
  },

  getters: {
    logopedistDetails: ({ logopedistDetails }) => logopedistDetails,
    logopedistOrders: ({ logopedistOrders }) => logopedistOrders
  }
}
