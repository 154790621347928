<template>
  <component
    :is="tag"
    :class="[
      $style.button,
      $style[type],
      { [$style.flex]: flex },
      { [$style.disabled]: disabled },
      { [$style.error]: error }
    ]"
    @click="$emit('click')"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    type: {
      type: String,
      default: 'default',
      require: false
    },
    flex: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'button'
    },
    error: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.button {
  padding: 0.75rem;
  border: 2px solid $white;
  border-radius: 0.625rem;
  font-size: 0.75rem;
  color: $white;
  text-transform: uppercase;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background: rgba($white, $alpha: 0.13);
  }

  &:active {
    background: rgba($white, $alpha: 0.3);
  }

  &.primary {
    padding: 0.9rem 0.625rem;
    background: $sunny-yellow;
    border: none;
    border-radius: 0.625rem;
    color: $white;
    font-size: 1.125rem;
    font-weight: 600;

    &:hover {
      background: $brilliant-orange-yellow;
    }

    &:active {
      background: $pale-orange-yellow;
    }
  }

  &.secondary {
    padding: 0.9rem 2.5rem;
    background: $wet-rainforest-light;
    border: none;
    border-radius: 0.625rem;
    font-weight: 600;
    color: $white;
    font-size: 0.875rem;

    &:hover {
      background: $green-crayola;
    }

    &:active {
      background: $ferm-crayola;
    }
  }

  &.tertiary {
    padding: 0.75rem 2rem;
    border: 2px solid $green-moss;
    border-radius: 0.625rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: $dull-gray;

    &:hover {
      border-color: $light-green-moss;
    }

    &:active {
      background: $green-moss;
    }
  }

  &.quaternary {
    padding: 0.7rem 2rem;
    background: $ferm-crayola;
    border: none;
    border-radius: 0.625rem;
    font-weight: 600;
    color: $white;
    font-size: 0.875rem;

    &:hover {
      background: $light-frerm-crayola;
    }

    &:active {
      background: $wet-rainforest-light;
    }
  }

  &.quintal {
    padding: 0.9rem 0.625rem;
    background: $sunny-yellow;
    border: none;
    border-radius: 1.875rem;
    color: $white;
    font-size: 1.125rem;
    font-weight: 600;

    &:hover {
      background: $brilliant-orange-yellow;
    }

    &:active {
      background: $pale-orange-yellow;
    }
  }

  &.delete {
    padding: 0.9rem 2.5rem;
    background: $error;
    border: none;
    border-radius: 0.625rem;
    font-weight: 600;
    color: $white;
    font-size: 0.875rem;

    &:hover {
      background: $little-tangerine;
    }

    &:active {
      background: $carmine-pink;
    }
  }

  &.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.disabled {
    background: $signal-gray;
    pointer-events: none;
    cursor: default;
  }

  &.error {
    box-shadow: inset 2px 2px 0px $error;
  }
  &.old {
    background: rgba(248, 248, 248, 1);
    border: 1px solid rgba(188, 188, 188, 1);
    padding: 13px, 23px, 13px, 23px;
    border-radius: 17px;
    color: black!important;
    font-size: 14px!important;
    font-weight: 500!important;
    line-height: 16.41px!important;
    &:hover {
      background: rgb(3, 3, 3, 0.2);
    }
  }
}
</style>
