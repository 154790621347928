import _ from 'lodash'
import users from '@/api/users'
import orders from '@/api/orders'
import Moment from "moment/moment";

export default {
  data() {
    return {
      page: 1,
      pageSize: 10,
      teachersPageCount: 0,
      orderPageCount: 0,
      promoPageCount: 0,
      logopedistsPageCount: 0,
      preschoolTeacherPageCount: 0,
      elementaryTeachersPageCount: 0,
      pageCount: 0,
      allItems: [],
      totalItems: []
    }
  },
  computed: {
    moment() {
      return Moment
    },
  },
  watch: {
    page: {
      handler() {
        console.log(this.page)
      }
    }
  },

  methods: {
    onPageChange(page) {
      this.totalItems = this.allItems[page - 1] || this.allItems[0]
      this.$router.push(`?page=${page}`)
    },
    async onArbitrationsPageChange(page) {
      const res = await orders.getArbitrations({
        pagination: {
          offset: page * 10 - 10,
          limit: 10
        },
        scan: {
          fields: [
            'studentFirstName',
            'studentLastName',
            'studentMiddleName',
            'teacherFirstName',
            'teacherLastName',
            'teacherMiddleName',
            'id'
          ],
          value: this.filterValue
        },
        order: {
          field: 'id',
          by: this.sortMethod
        }
      })
      this.totalItems = res.data.result.rows
      this.$router.push(`?page=${page}`)
    },
    async onNewslettersPageChange(page) {
      const res = await orders.getNewsletters({
        pagination: {
          offset: page * 10 - 10,
          limit: 10
        },
        scan: {
          fields: [
            'id'
          ],
          value: this.filterValue
        },
        order: {
          field: 'id',
          by: this.sortMethod
        }
      })
      this.totalItems = res.data.result.rows
      this.$router.push(`?page=${page}`)
    },
    setupOrderPagination(allItems, pageCount) {
      this.totalItems = allItems
      this.orderPageCount = Math.ceil(pageCount / 10)
    },
    setupPromoPagination(allItems, pageCount) {
      this.totalItems = allItems
      this.promoPageCount = Math.ceil(pageCount / 10)
    },
    setupPagination(allItems) {
      this.allItems = _.chunk(allItems, this.pageSize)
      this.pageCount = _.size(this.allItems)
      this.totalItems = this.allItems[this.$route.query.page - 1] || this.allItems[0]
    },
    async onTeachersPageChange(page, limit, filter = {}) {
      const res = await users.getTeachersWithLimit({
        offset: page * limit - limit,
        limit
      }, this.filterValue, filter)
      this.totalItems = res.data.result.rows
      this.$router.push(`?page=${page}`)
    },
    setupTeachersPagination(allItems, pageCount, count = 10) {
      this.totalItems = allItems
      this.teachersPageCount = Math.ceil(pageCount / count)
    },
    async onLogopededistPageChange(page, limit, filter = {}) {
      const res = await users.getLogopedistsWithLimit({
        offset: page * limit - limit,
        limit
      }, this.filterValue, filter)
      this.totalItems = res.data.result.rows
      this.$router.push(`?page=${page}`)
    },
    setupLogopedistsPagination(allItems, pageCount, count = 10) {
      this.totalItems = allItems
      this.logopedistsPageCount = Math.ceil(pageCount / count)
    },
    async onPreschoolTeacherPageChange(page, limit, filter = {}) {
      const res = await users.getPreschoolTeachersWithLimit({
        offset: page * limit - limit,
        limit
      }, this.filterValue, filter)
      this.totalItems = res.data.result.rows
      this.$router.push(`?page=${page}`)
    },
    setupPreschoolTeacherPagination(allItems, pageCount, count) {
      this.totalItems = allItems
      this.preschoolTeacherPageCount = Math.ceil(pageCount / count)
    },
    async onElementaryTeachersPageChange(page) {
      const res = await users.getElementaryTeachers(page * 10 - 10)
      this.totalItems = res.data.result.rows
      this.$router.push(`?page=${page}`)
    },
    setupElementaryTeachersPagination(allItems, pageCount) {
      this.totalItems = allItems
      this.elementaryTeachersPageCount = Math.ceil(pageCount / 10)
    },
    setupTeacherReportPagination(allItems, count) {
      this.totalItems = allItems
      this.pageCount = Math.ceil(count / 10)
    },
    setupWithdrawalMoneyPagination(allItems, count) {
      this.totalItems = allItems
      this.pageCount = Math.ceil(count / 10)
    },
    setupUserReportPagination(allItems, count) {
      this.totalItems = allItems
      this.pageCount = Math.ceil(count / 10)
    },
    setupPaymentReportPagination(allItems, count) {
      this.totalItems = allItems
      this.pageCount = Math.ceil(count / 20)
    }
  }
}
