<template>
  <modal-wrapper :title="title" @close="close">
    <div :class="$style.modal__alert">
      <i />
      <div :class="$style.modal__alertText">
        <span>{{ message }}</span>
      </div>
      <div>
        <custom-button
          :class="$style.modal__alertButton"
          type="tertiary"
          center
          @click="close"
        >
          Готово
        </custom-button>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/basic/ModalWrapper'
import CustomButton from '@/basic/Button'
export default {
  name: 'SuccessModal',
  components: {
    ModalWrapper,
    CustomButton
  },
  computed: {
    params() {
      return this.$store.getters['modalService']
    },
    title() {
      return this.params?.title ?? 'Title'
    },
    message() {
      return this.params?.message ?? 'Message'
    }
  },
  methods: {
    close() {
      this.$store.dispatch('closeModal', { success: true })
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/breakpoints.scss';

.modal {
  &__alert {
    padding: 1.25rem;
    min-width: 18.75;
  }

  &__alertText {
    font-weight: 400;
    text-align: center;
    margin-bottom: 1.875rem;

    span {
      white-space: pre-line;
    }
  }

  &__alertButton {
    width: 100%;
  }
}
</style>
