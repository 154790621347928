import employeesApi from '@/api/employees'
import { EmployeeRoles } from '@/application/constants'

export default {
  state: {
    authToken: null,
    user: null
  },

  mutations: {
    setAuthToken(state, token) {
      state.authToken = token
    },
    setUserProfile(state, profile) {
      state.user = profile
    }
  },

  actions: {
    async getEmployeeProfile({ commit }) {
      try {
        const {
          data: { success, result }
        } = await employeesApi.getEmployeeProfile()

        if (success && result) {
          commit('setUserProfile', result)
        }
      } catch (error) {
        console.warn(error)
        commit(
          'setNoticeData',
          `<p style="margin: 2rem 3rem; font-size: 14px">Ошибка получения данных по сотруднику с сервера!</p>`,
          { root: true }
        )
        commit('setNotice', 'ErrorNotice', { root: true })
        setTimeout(() => {
          commit('setNoticeData', null, { root: true })
          commit('setNotice', null, { root: true })
        }, 5000)
        throw new Error(error)
      }
    }
  },

  getters: {
    authToken: ({ authToken }) => authToken,
    userRoleId: ({ user }) => user?.roleId ?? null,
    isAdmin: ({ user }) => user?.roleId === EmployeeRoles.ADMINISTRATOR ?? null,
    isManager: ({ user }) => user?.roleId === EmployeeRoles.MANAGER ?? null,
    isManagerKc: ({ user }) => user?.roleId === EmployeeRoles.MANAGER_KC ?? null,
    isTechnicalSupport: ({ user }) => user?.roleId === EmployeeRoles.TECHNICAL_SUPPORT ?? null
  }
}
