<template>
  <notice :class="$style.info__notice" :text="text" :type="noticeType">
    <img src="@/assets/icons/info-notice.svg" alt="info" />
  </notice>
</template>

<script>
import Notice from '@/components/notices/Notice'

export default {
  components: {
    Notice
  },
  props: {
    error: {
      type: Boolean,
      default: false
    },
    noticeText: {
      type: String,
      default: `<p style="margin: 2rem 3rem; font-size: 14px">Информативное оповещение!</p>`
    }
  },
  computed: {
    noticeData() {
      return this.$store.getters.noticeData
    },

    noticeType() {
      return this.$store.getters.noticeType
    },

    text() {
      return this.noticeData ?? this.noticeText
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/breakpoints';

.info {
  &__notice {
    position: fixed;
    top: 30%;
    left: 40%;
    right: 20%;
    z-index: 100;
    width: 40%;
  }
}

@include for-tablet {
  .info {
    &__notice {
      left: 30%;
      right: 10%;
      width: 60%;
    }
  }
}
</style>
