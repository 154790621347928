export default {
  state: {
    chat: {},
    chats: [],
    isReceivedChatMessage: false,
    images: [],
    loading: false
  },
  mutations: {
    setChats(state, chats) {
      state.chats = chats
    },
    setChat(state, chat) {
      state.chat = chat
    },
    clearChat(state) {
      state.chat = {}
    },
    'SOCKET_server-messages-set'(state, message) {
      state.chat.messages.push(message)
    },
    SOCKET_updateUsers(state, users) {
      state.users = users
    },
    setIsReceivedChatMessage(state, value) {
      state.isReceivedChatMessage = value
    },
    setChatImages(state, images) {
      state.images = images
    },
    pushChatImage(state, image) {
      state.images.push({ id: state.images.length, ...image })
    },
    deleteChatImage(state, imageId) {
      state.images = state.images.filter((image) => image.id !== imageId)
    },
    setChatLoadingStatus(state, status) {
      state.loading = status
    }
  },
  getters: {
    chats: (state) => state.chats,
    chat: (state) => state.chat,
    isReceivedChatMessage: (state) => state.isReceivedChatMessage,
    images: (state) => state.images,
    loading: (state) => state.loading
  }
}
