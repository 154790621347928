<template>
  <div class="modalService">
    <transition name="fade" mode="in-out">
      <component :is="modal" v-if="modal" />
    </transition>
  </div>
</template>

<script>
import Confirm from '../modals/Confirm.vue'
import Alert from '../modals/Alert.vue'
import TeacherModal from '../modals/TeacherModal.vue'
import CancelOrder from '../modals/CancelOrder.vue'
import Homework from '../modals/Homework.vue'
import Promo from '../modals/Promo.vue'
import PromoDetails from '../modals/PromoDetails.vue'
import CommentModal from '../modals/CommentModal.vue'

export default {
  name: 'ModalService',
  components: {
    Confirm,
    Alert,
    TeacherModal,
    CancelOrder,
    Homework,
    Promo,
    PromoDetails,
    CommentModal
  },
  computed: {
    modal() {
      return this.$store.getters['modal']
    }
  }
}
</script>

<style module>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
</style>
