<template>
  <div :class="$style.sideBar">
    <h1 style="font-size: 36px; font-weight: 500">Меню {{ menuText }}</h1>
    <nav :class="$style.sideBar__nav">
      <button
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'Orders' }
        ]"
        @click="goTo('/orders?page=1')"
        :disabled="$route.name === 'Orders'"
      >
        Заказы
      </button>
      <button
      v-if='!isTechnicalSupport'
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'Chats' || $route.name === 'Chat' }
        ]"
        @click="goTo('/chats?page=1')"
        :disabled="$route.name === 'Chats'"
      >
        {{ `Чаты ${isReceivedChatMessage ? '(+)' : ''}` }}
      </button>
      <button
        v-if="isAdmin"
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'WithdrawalMoney' }
        ]"
        @click="goTo('/withdrawalMoney?page=1')"
        :disabled="$route.name === 'WithdrawalMoney'"
      >
        Вывод денег
      </button>
      <button
        v-if="isAdmin"
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'Employees' }
        ]"
        @click="goTo('/employees?page=1')"
        :disabled="$route.name === 'Employees'"
      >
      Сотрудники
      </button>
      <button
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'Teachers' }
        ]"
        @click="goTo('/teachers?page=1')"
        :disabled="$route.name === 'Teachers'"
      >
        Преподаватели
      </button>
      <button
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'Logopedists' }
        ]"
        @click="goTo('/logopedists?page=1')"
        :disabled="$route.name === 'Logopedists'"
      >
        Логопеды
      </button>
      <button
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'PreschoolTeachers' }
        ]"
        @click="goTo('/preschoolTeachers?page=1')"
        :disabled="$route.name === 'PreschoolTeachers'"
      >
        Педагоги дошкольного образования
      </button>
      <button
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'Parents' }
        ]"
        @click="goTo('/parents?page=1')"
        :disabled="$route.name === 'Parents'"
      >
        Родители
      </button>
      <button
        v-if="!isManager"
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'Students' }
        ]"
        @click="goTo('/students?page=1')"
        :disabled="$route.name === 'Students'"
      >
        Ученики
      </button>
      <button
        v-if="isAdmin"
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'Appeals' }
        ]"
        @click="goTo('/appeals?page=1')"
        :disabled="$route.name === 'Appeals'"
      >
        Обращения
      </button>
      <button
        v-if="isAdmin"
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'Notifications' }
        ]"
        @click="goTo('/notifications?page=1')"
        :disabled="$route.name === 'Notifications'"
      >
        Уведомления
      </button>
      <button
        v-if="isAdmin"
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'TeachersReport' }
        ]"
        @click="goTo('/teachersReport')"
        :disabled="$route.name === 'TeacherReport'"
      >
        Отчет по педагогам
      </button>
      <button
        v-if="isAdmin"
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'UsersReport' }
        ]"
        @click="goTo('/usersReport')"
        :disabled="$route.name === 'UsersReport'"
      >
        Отчет по пользователям
      </button>
      <button
        v-if="isAdmin || isManagerKc"
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'OrdersReport' }
        ]"
        @click="goTo('/ordersReport')"
        :disabled="$route.name === 'OrdersReport'"
      >
        Отчет по заказам
      </button>
      <button
        v-if="isAdmin"
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'ParentsReport' }
        ]"
        @click="goTo('/parentsReport')"
        :disabled="$route.name === 'ParentsReport'"
      >
        Отчет по родителям
      </button>
      <button
        v-if="!isManager"
        :class="[
          $style.sideBar__link,
          { [$style.sideBar__link_active]: $route.name === 'Arbitrations' }
        ]"
        @click="goTo('/arbitrations')"
        :disabled="$route.name === 'Arbitrations'"
      >
        Арбитраж
      </button>
      <button
          v-if="isAdmin"
          :class="[
            $style.sideBar__link,
            { [$style.sideBar__link_active]: $route.name === 'PaymentsReport'}
        ]"
          @click="goTo('/paymentsReport')"
          :disabled="$route.name === 'PaymentsReport'"
      >
        Отчет по оплатам
      </button>
      <button
        v-if="isAdmin"
        :class="[
          $style.sideBar__link,
          {
            [$style.sideBar__link_active]:
              $route.name === 'Newsletters' || $route.name === 'NewslettersNew'
          }
        ]"
        @click="goTo('/newsletters')"
        :disabled="$route.name === 'Newsletters'"
      >
        Рассылка
      </button>
      <button
        v-if="isAdmin || isManager || isManagerKc"
        :class="[
          $style.sideBar__link,
          {
            [$style.sideBar__link_active]:
              $route.name === 'Promos' || $route.name === 'PromosNew'
          }
        ]"
        @click="goTo('/promos?page=1')"
        :disabled="$route.name === 'Promos'"
      >
        Промокоды
      </button>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { EmployeeRoles } from '@/application/constants'

export default {
  computed: {
    ...mapGetters(['isReceivedChatMessage', 'userRoleId', 'isAdmin', 'isManager', 'isTechnicalSupport', 'isManagerKc']),

    menuText() {
      switch (this.userRoleId) {
        case EmployeeRoles.ADMINISTRATOR:
          return 'админа'
        case EmployeeRoles.MANAGER:
          return 'менеджера'
        case EmployeeRoles.METHODIST_LOGOPEDIST:
          return 'методиста-логопеда'
        case EmployeeRoles.METHODIST_PRESCHOOL_TEACHER:
          return 'методиста дошкольной подготовки'
        default:
          return ''
      }
    }
  },
  methods: {
    ...mapMutations(['setIsReceivedChatMessage', 'setAuthRole']),
    goTo(path) {
      if (path.includes('chats') && this.isReceivedChatMessage) {
        this.setIsReceivedChatMessage(false)
      }
      if (this.$route.path === path) {
        return
      } else {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.sideBar {
  padding: 1rem;
  background: $medium-gray;

  &__nav {
    margin: 2rem 0 0;
  }

  &__link {
    width: 100%;
    padding: 0.5rem;
    margin: 0.75rem 0 0;
    background: $white;
    font-size: 1.125rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;

    &_active {
      background: $medium-gray;

      &:hover {
        padding: 0.5rem !important;
        cursor: default;
      }
      &:disabled {
        color: #000;
      }
    }

    &:hover {
      padding: 0.5rem 0.5rem 0.5rem 0.75rem;
    }
  }
}
</style>
