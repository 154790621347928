<template>
  <modal-wrapper :title="title" @close="close">
    <div :class="$style.modal__alert">
      <i />
      <div :class="$style.modal__alertText">
        <span>{{ message }}</span>
      </div>
      <div
        v-for="status in cancellationReasons"
        :key="status.id"
        :class="$style.modal__flex"
      >
        <InputRadio :value="selectedStatusId === status.id" @input="setStatus(status.id)" />
        <p>{{ status.name }}</p>
      </div>
      <div :class="$style.modal__buttonsContainer">
        <custom-button
          :class="$style.modal__button"
          type="tertiary"
          :center="true"
          @click="close"
        >
          Нет
        </custom-button>
        <custom-button
          :class="$style.modal__button"
          type="tertiary"
          :center="true"
          :disabled="selectedStatusId === null"
          @click="confirm"
        >
          Да
        </custom-button>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/basic/ModalWrapper'
import CustomButton from '@/basic/Button'
import InputRadio from '@/basic/InputRadio'

// import { ManualStatusesCancelOrder } from '@/application/constants'

// const manualStatuses = [
//   {
//     id: ManualStatusesCancelOrder.ORDER_CREATION_ERROR,
//     text: 'ошибка создания заказа'
//   },
//   {
//     id: ManualStatusesCancelOrder.STUDENT_REQUEST_TO_CANCEL_ORDER,
//     text: 'запрос ученика на отмену занятия'
//   },
//   {
//     id: ManualStatusesCancelOrder.TEACHER_REQUEST_TO_CANCEL_ORDER,
//     text: 'запрос преподавателя на отмену занятия'
//   }
// ]

export default {
  name: 'ConfirmModal',
  components: {
    ModalWrapper,
    CustomButton,
    InputRadio
  },
  data() {
    return {
      // manualStatusesCancelOrder: this.cancellationReasons,
      selectedStatusId: null
    }
  },
  computed: {
    params() {
      return this.$store.getters['modalService']
    },
    title() {
      return this.params?.title ?? 'Title'
    },
    message() {
      return this.params?.message ?? 'Message'
    },
    cancellationReasons() {
      return this.$store.getters['constants']?.ordersCancellationReasons.filter(e => e.forDashboard) ?? []
    }
  },
  methods: {
    close(config = { success: true, result: false }) {
      this.$store.dispatch('closeModal', config)
    },
    confirm() {
      this.close({
        success: true,
        result: true,
        params: { selectedManualStatusId: this.selectedStatusId }
      })
    },
    setStatus(id) {
      if (this.selectedStatusId === id) {
        this.selectedStatusId = null
      } else {
        this.selectedStatusId = id
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/breakpoints.scss';

.modal {
  &__alert {
    padding: 1.25rem;
    min-width: 18.75rem;
  }

  &__alertText {
    font-weight: 400;
    text-align: center;
    margin-bottom: 1rem;

    span {
      white-space: pre-line;
    }
  }

  &__flex {
    display: flex;
    align-items: center;
    p {
      font-size: 0.85rem;
    }
  }

  &__buttonsContainer {
    display: flex;
    margin: 1rem 0 0;
  }

  &__button ~ &__button {
    margin-left: 1.25rem;
  }

  &__button {
    width: 100%;
  }
}
</style>
