import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueSocketIO from 'vue-socket.io'
import socketIO from 'socket.io-client'
import Paginate from 'vuejs-paginate'
import VueCookies from 'vue-cookies'
import Multiselect from 'vue-multiselect'

import httpServicePlugin from './plugins/axios'
import Constants from '@/application/constants'
import store from './store'

import '@/assets/styles/common.scss'
import VModal from 'vue-js-modal'

import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask)
Vue.use(VModal)
Vue.use(VueCookies)

Vue.config.productionTip = false
Vue.use(httpServicePlugin)
//Vue.$setupAxios()
Vue.use(
  new VueSocketIO({
    debug: true,
    connection: socketIO(Constants.RTC_URL, {
      autoConnect: true,
      upgrade: true,
      extraHeaders: {
        'x-access-token': ''
      }
    }),
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
    }
  })
)
Vue.component('pagination', Paginate)
Vue.component('multiselect', Multiselect)
router.beforeEach((to, from, next) => {
  const token = VueCookies.get('x-token') && sessionStorage.getItem('x-access-token')
  if (to.name === 'Login') {
    next()
  } else if (!token) {
    VueCookies.set('follow-path', to.fullPath, 60)
    next({ name: 'Login' })
  } else {
    next()
  }
})

new Vue({
  sockets: {
    connect: function () {
      console.log('socket connected')
    },
    customEmit: function () {
      console.log(
        'this method was fired by the socket server. eg: io.emit("customEmit", data)'
      )
    }
  },
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
