import userApi from '@/api/users'
import orderApi from '@/api/orders'

export default {
  state: {
    parentDetails: {},
    parentOrders: []
  },

  mutations: {
    setParentDetails(state, parentDetails) {
      state.parentDetails = parentDetails
    },
    setParentOrders(state, orders) {
      state.parentOrders = orders
    }
  },

  actions: {
    async getParent({ commit }, parentId) {
      try {
        const { data } = await userApi.getParent({
          filter: {
            id: parentId
          }
        })

        if (data?.success) {
          commit('setParentDetails', data.result.rows[0])
          return data.result.rows[0]
        }
      } catch (error) {
        console.warn(error)
      }
    },

    async getParentOrders({ commit }, filter) {
      try {
        const { data } = await orderApi.getOrders(filter)

        if (data?.success) {
          commit('setParentOrders', data.result)
        }
      } catch (error) {
        console.warn(error)
      }
    }
  },

  getters: {
    parentDetails: ({ parentDetails }) => parentDetails,
    parentOrders: ({ parentOrders }) => parentOrders
  }
}
