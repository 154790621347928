var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.sideBar},[_c('h1',{staticStyle:{"font-size":"36px","font-weight":"500"}},[_vm._v("Меню "+_vm._s(_vm.menuText))]),_c('nav',{class:_vm.$style.sideBar__nav},[_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'Orders' }
      ],attrs:{"disabled":_vm.$route.name === 'Orders'},on:{"click":function($event){return _vm.goTo('/orders?page=1')}}},[_vm._v(" Заказы ")]),(!_vm.isTechnicalSupport)?_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'Chats' || _vm.$route.name === 'Chat' }
      ],attrs:{"disabled":_vm.$route.name === 'Chats'},on:{"click":function($event){return _vm.goTo('/chats?page=1')}}},[_vm._v(" "+_vm._s(`Чаты ${_vm.isReceivedChatMessage ? '(+)' : ''}`)+" ")]):_vm._e(),(_vm.isAdmin)?_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'WithdrawalMoney' }
      ],attrs:{"disabled":_vm.$route.name === 'WithdrawalMoney'},on:{"click":function($event){return _vm.goTo('/withdrawalMoney?page=1')}}},[_vm._v(" Вывод денег ")]):_vm._e(),(_vm.isAdmin)?_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'Employees' }
      ],attrs:{"disabled":_vm.$route.name === 'Employees'},on:{"click":function($event){return _vm.goTo('/employees?page=1')}}},[_vm._v(" Сотрудники ")]):_vm._e(),_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'Teachers' }
      ],attrs:{"disabled":_vm.$route.name === 'Teachers'},on:{"click":function($event){return _vm.goTo('/teachers?page=1')}}},[_vm._v(" Преподаватели ")]),_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'Logopedists' }
      ],attrs:{"disabled":_vm.$route.name === 'Logopedists'},on:{"click":function($event){return _vm.goTo('/logopedists?page=1')}}},[_vm._v(" Логопеды ")]),_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'PreschoolTeachers' }
      ],attrs:{"disabled":_vm.$route.name === 'PreschoolTeachers'},on:{"click":function($event){return _vm.goTo('/preschoolTeachers?page=1')}}},[_vm._v(" Педагоги дошкольного образования ")]),_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'Parents' }
      ],attrs:{"disabled":_vm.$route.name === 'Parents'},on:{"click":function($event){return _vm.goTo('/parents?page=1')}}},[_vm._v(" Родители ")]),(!_vm.isManager)?_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'Students' }
      ],attrs:{"disabled":_vm.$route.name === 'Students'},on:{"click":function($event){return _vm.goTo('/students?page=1')}}},[_vm._v(" Ученики ")]):_vm._e(),(_vm.isAdmin)?_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'Appeals' }
      ],attrs:{"disabled":_vm.$route.name === 'Appeals'},on:{"click":function($event){return _vm.goTo('/appeals?page=1')}}},[_vm._v(" Обращения ")]):_vm._e(),(_vm.isAdmin)?_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'Notifications' }
      ],attrs:{"disabled":_vm.$route.name === 'Notifications'},on:{"click":function($event){return _vm.goTo('/notifications?page=1')}}},[_vm._v(" Уведомления ")]):_vm._e(),(_vm.isAdmin)?_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'TeachersReport' }
      ],attrs:{"disabled":_vm.$route.name === 'TeacherReport'},on:{"click":function($event){return _vm.goTo('/teachersReport')}}},[_vm._v(" Отчет по педагогам ")]):_vm._e(),(_vm.isAdmin)?_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'UsersReport' }
      ],attrs:{"disabled":_vm.$route.name === 'UsersReport'},on:{"click":function($event){return _vm.goTo('/usersReport')}}},[_vm._v(" Отчет по пользователям ")]):_vm._e(),(_vm.isAdmin || _vm.isManagerKc)?_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'OrdersReport' }
      ],attrs:{"disabled":_vm.$route.name === 'OrdersReport'},on:{"click":function($event){return _vm.goTo('/ordersReport')}}},[_vm._v(" Отчет по заказам ")]):_vm._e(),(_vm.isAdmin)?_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'ParentsReport' }
      ],attrs:{"disabled":_vm.$route.name === 'ParentsReport'},on:{"click":function($event){return _vm.goTo('/parentsReport')}}},[_vm._v(" Отчет по родителям ")]):_vm._e(),(!_vm.isManager)?_c('button',{class:[
        _vm.$style.sideBar__link,
        { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'Arbitrations' }
      ],attrs:{"disabled":_vm.$route.name === 'Arbitrations'},on:{"click":function($event){return _vm.goTo('/arbitrations')}}},[_vm._v(" Арбитраж ")]):_vm._e(),(_vm.isAdmin)?_c('button',{class:[
          _vm.$style.sideBar__link,
          { [_vm.$style.sideBar__link_active]: _vm.$route.name === 'PaymentsReport'}
      ],attrs:{"disabled":_vm.$route.name === 'PaymentsReport'},on:{"click":function($event){return _vm.goTo('/paymentsReport')}}},[_vm._v(" Отчет по оплатам ")]):_vm._e(),(_vm.isAdmin)?_c('button',{class:[
        _vm.$style.sideBar__link,
        {
          [_vm.$style.sideBar__link_active]:
            _vm.$route.name === 'Newsletters' || _vm.$route.name === 'NewslettersNew'
        }
      ],attrs:{"disabled":_vm.$route.name === 'Newsletters'},on:{"click":function($event){return _vm.goTo('/newsletters')}}},[_vm._v(" Рассылка ")]):_vm._e(),(_vm.isAdmin || _vm.isManager || _vm.isManagerKc)?_c('button',{class:[
        _vm.$style.sideBar__link,
        {
          [_vm.$style.sideBar__link_active]:
            _vm.$route.name === 'Promos' || _vm.$route.name === 'PromosNew'
        }
      ],attrs:{"disabled":_vm.$route.name === 'Promos'},on:{"click":function($event){return _vm.goTo('/promos?page=1')}}},[_vm._v(" Промокоды ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }