import Vue from 'vue'

export default {
  // {filter, order: {field, by}, pagination: {offset, limit}}
  getOrders(data) {
    return Vue.axios.post('/dashboard/orders/list', data)
  },
  async getOrder(id) {
    return (await Vue.axios.get(`/dashboard/orders/${id}`)).data
  },
  async getTypesForOrders() {
    return (await Vue.axios.get('/dashboard/orders/types')).data
  },
  getArbitrations(data) {
    return Vue.axios.post('/dashboard/arbitration/list', data)
  },
  getNewsletters(data) {
    return Vue.axios.post('/dashboard/mailingLists/list', data)
  },
  getTeachers(id) {
    return Vue.axios.get('/dashboard/teachers/match/' + id)
  },
  getTeachersPedagogs(id) {
    return Vue.axios.get('/dashboard/teachers/match/extended/' + id)
  },
  getLogopedists(id) {
    return Vue.axios.get('/dashboard/logopedists/match/extended/' + id)
  },
  getPreschoolTeachers(id) {
    return Vue.axios.get('/dashboard/preschoolTeachers/match/extended/' + id)
  },
  setTeachers(orderId, id) {
    return Vue.axios.patch('/dashboard/orders/' + orderId, { teacherId: id })
  },
  getTypes() {
    return Vue.axios.get('/dashboard/notifications/types')
  },
  getOrderInfo() {
    return Vue.axios.get('/dashboard/orders/types')
  },
  getTargetInfo() {
    return Vue.axios.get('/dashboard/mailingLists/targets')
  },
  deleteTeachers(id) {
    return Vue.axios.delete('/dashboard/orders/' + id)
  },

  setMessage(id) {
    return Vue.axios.post('/dashboard/notifications ', {
      sendingTypeId: [1, 2],
      text: 'Вы назначены на заказ',
      userId: id
    })
  },
  getAllUsers() {
    return Vue.axios.post('/dashboard/users', {
      filter: {
        roleId: 1
      }
    })
  },
  async checkCanCreateOrder(data) {
    return await Vue.axios.post('/dashboard/orders/checkCanCreateOrder', data)
  },
  async createFirstSession(data) {
    return await Vue.axios.post('/dashboard/orders/request/logopedistic', data)
  },
  async createSecondSession(data) {
    return await Vue.axios.post('/dashboard/orders/secondSession/logopedistic', data)
  },
  async createLogopedisticOrder(data) {
    return await Vue.axios.post('/dashboard/orders/logopedistic', data)
  },
  async downloadingFile(groupId, type, file) {
    return await Vue.axios.post(`/dashboard/orders/request/documents/${groupId}/${type}`, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  async changeAttributes({ updateData, groupId }) {
    return await Vue.axios.patch(`/dashboard/orders/request/logopedistic/attributes/${groupId}`, updateData)
  },
  async createOrder(data) {
    return (await Vue.axios.post('dashboard/orders', data)).data
  },
  createNewsletter(title, message, sendByMail, sendByPush, recipients, users) {
    return Vue.axios.post('/dashboard/mailingLists', {
      title,
      message,
      sendByMail,
      sendByPush,
      recipients,
      users
    })
  },
  createТrialOrder(studentId, subjectId, datetime) {
    return Vue.axios.post('dashboard/orders', {
      isTrial: true,
      durationId: 1,
      studentId,
      subjectId,
      datetime
    })
  },
  getAllSubjects() {
    return Vue.axios.get('/dashboard/subjects')
  },
  lessonRecord(orderId, userId, type) {
    const config = {
      method: 'get',
      url: `${process.env.VUE_APP_API_URL}/dashboard/orders/record/${type}/${orderId}/${userId}`,
      responseType: 'blob'
    }

    return Vue.axios(config)
  },
  changeStatus(statusId, expertConclusionLink, orderId) {
    return Vue.axios.post(
      `${process.env.VUE_APP_API_URL}/dashboard/arbitration/${orderId}/status`,
      {
        statusId,
        expertConclusionLink
      }
    )
  },
  updateOrder(data) {
    return Vue.axios.post(`/dashboard/orders/update`, data)
  },
  updateAfterConsultation(data) {
    return Vue.axios.patch('/dashboard/orders/consultation', data)
  },
  cancelOrderById({ orderId, cancellationReasonId }) {
    return Vue.axios.post('/dashboard/orders/cancel', { orderId, cancellationReasonId })
  },
  async declineOrder(data) {
    return await Vue.axios.post(`/dashboard/orders/distribution/decline`, data)
  },
  async getSpecialistDeclineType() {
    return (await Vue.axios.get(`/dashboard/orders/specialistsDeclinesTypes`)).data
  },
  getFileForLogopedistSession(groupId, type) {
    return Vue.axios.get(`dashboard/orders/request/documents/${groupId}/${type}`, {
      responseType: 'arraybuffer'
    })
  },
  goToRecord(orderId) {
    return Vue.axios.get(`/dashboard/orders/records/${orderId}`)
  },
  getInit() {
    return Vue.axios.get(`/public/init/`)
  },
  hideRating(data) {
    return Vue.axios.patch(`/dashboard/orders/rating`, data)
  }
}
