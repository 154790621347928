import Vue from 'vue'

export default {
  getFile(id) {
    return Vue.axios.post(
      `dashboard/homework/documents/download`,
      { id },
      { responseType: 'arraybuffer' }
    )
  }
}
