export default {
  state: {
    notifications: []
  },
  mutations: {
    setNotifications(state, notification) {
      state.notifications = [...state.notifications, notification]
    },
    filterNotifications(state, id) {
      state.notifications = state.notifications.filter(
        (notification) => JSON.parse(notification.data).id !== id
      )
    }
  },
  getters: {
    notifications: (state) => state.notifications
  }
}
