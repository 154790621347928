<template>
  <div :class="[$style.notice, $style[type]]">
    <div :class="[$style.notice__header, { [$style.notice__header_error]: error }]">
      <img v-if="error" src="@/assets/icons/warning-notice.svg" alt="warning" />
      <slot v-else />
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div :class="$style.notice__content" v-html="text" />
    <!--eslint-enable-->
  </div>
</template>

<script>
export default {
  name: 'CusomNotice',
  props: {
    error: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';

.notice {
  background: $white;
  box-shadow: inset 0.25rem 0.25rem 0px $gainsborough;
  border-radius: 0.625rem;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5rem;
    background: $ferm-crayola;
    border-radius: 0.625rem 0.625rem 0 0;
    box-shadow: inset 0.25rem 0.25rem 0px $may-green;

    &_error {
      background: $carmine-pink !important;
      box-shadow: inset 0.25rem 0.25rem 0px $brown-burgundy !important;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60%;
    padding: 1rem 2rem;
    color: $wet-asphalt;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }

  &.secondary {
    background: $smoky-white;
    box-shadow: none;

    .notice__header {
      background: $twitter-color;
      box-shadow: none;

      &_error {
        box-shadow: none !important;
      }
    }
  }

  &.tertiary {
    background: $smoky-white;
    box-shadow: none;

    .notice__header {
      background: $ferm-crayola;
      box-shadow: none;

      &_error {
        box-shadow: none !important;
      }
    }
  }
}

@include for-mobile {
  .notice {
    &__content {
      height: auto;
    }
  }
}
</style>
