<template>
  <modal-wrapper :cancellable="false" hidden type="homework">
    <div :class="$style.modal__homework">
      <button :class="$style.modal__homework__close" @click="close">
        <img src="@/assets/icons/green-rounded-cross.svg" alt="X" />
        <span>закрыть</span>
      </button>

      <div v-if="loading" :class="$style.modal__homework__loading">
        <Loading />
      </div>

      <div v-else :class="$style.modal__homework__wrapper">
        <div :class="$style.modal__homework__header">
          <div :class="$style.modal__homework__header_flex">
            <img src="@/assets/icons/homework-lesson-black.svg" alt="homework" />
            <span :class="$style.modal__homework__header_status"
              >Задание {{ homeworkStatusName }}</span
            >
          </div>
          <div :class="$style.modal__homework__header_flex">
            <span :class="$style.modal__homework__header_deadline">Срок сдачи:</span>
            <img src="@/assets/icons/calendar-lesson.svg" alt="date" /><span
              :class="$style.modal__homework__header_text"
              >{{ homeworkDate }}</span
            >
            <img src="@/assets/icons/time-lesson.svg" alt="time" /><span
              :class="$style.modal__homework__header_text"
              >{{ homeworkTime }}</span
            >
          </div>
        </div>

        <div :class="$style.modal__homework__textarea">
          <p>{{ order.homework.teacherComment }}</p>
        </div>

        <div :class="$style.modal__homework__files">
          <div v-if="homeworkFiles.length" :class="$style.modal__homework__files_wrapper">
            <p :class="$style.modal__homework__wrapper__subtitle">Файлы</p>
            <div
              v-for="(file, i) in homeworkFiles"
              :key="i"
              :class="$style.modal__homework__files__file"
            >
              <div :class="$style.modal__homework__files__file_icon">
                <img src="@/assets/icons/file-wrapper.svg" alt="wrapper" />
                <span>{{ printFileExtension(file.name) }}</span>
              </div>
              <span :class="$style.modal__homework__files__file_text">{{
                printFileName(file.name)
              }}</span>
              <img
                :class="$style.modal__homework__files__file_close"
                src="@/assets/icons/download-homework.svg"
                alt="close"
                @click="downloadHomeworkFile(file)"
              />
            </div>
          </div>
          <div
            v-if="additionalMaterials.length"
            :class="$style.modal__homework__files_wrapper"
          >
            <p :class="$style.modal__homework__wrapper__subtitle">Дополнительные материалы</p>
            <div
              v-for="(file, i) in additionalMaterials"
              :key="i"
              :class="$style.modal__homework__files__file"
            >
              <div :class="$style.modal__homework__files__file_icon">
                <img src="@/assets/icons/file-wrapper.svg" alt="wrapper" />
                <span>{{ printFileExtension(file.name) }}</span>
              </div>
              <span :class="$style.modal__homework__files__file_text">{{
                printFileName(file.name)
              }}</span>
              <img
                :class="$style.modal__homework__files__file_close"
                src="@/assets/icons/download-homework.svg"
                alt="close"
                @click="downloadHomeworkFile(file)"
              />
            </div>
          </div>
        </div>

        <div v-if="studentFiles.length || studentComment">
          <h3 v-if="studentComment">Ответ ученика</h3>
          <div v-if="studentComment" :class="$style.modal__homework__textarea">
            <p>{{ studentComment }}</p>
          </div>
          <div v-if="studentFiles.length" :class="$style.modal__homework__answer">
            <p :class="$style.modal__homework__wrapper__subtitle">Файлы</p>
            <div
              v-for="(file, i) in studentFiles"
              :key="i"
              :class="$style.modal__homework__files__file"
            >
              <div :class="$style.modal__homework__files__file_icon">
                <img src="@/assets/icons/file-wrapper.svg" alt="wrapper" />
                <span>{{ printFileExtension(file.name) }}</span>
              </div>
              <span :class="$style.modal__homework__files__file_text">{{
                printFileName(file.name)
              }}</span>
              <img
                :class="$style.modal__homework__files__file_close"
                src="@/assets/icons/download-homework.svg"
                alt="close"
                @click="downloadHomeworkFile(file)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/basic/ModalWrapper'
import Loading from '@/basic/Loading'

import Moment from 'moment'
import homeworkApi from '@/api/homeworkApi'
import { mapGetters } from 'vuex'

const MSK = 3

export default {
  name: 'HomeworkModal',
  components: {
    ModalWrapper,
    Loading
  },
  data() {
    return {
      loading: false,
      homeworkFiles: [],
      additionalMaterials: [],
      studentFiles: [],
      studentComment: ''
    }
  },
  computed: {
    ...mapGetters({
      order: 'orderDetails'
    }),
    orderId() {
      return Number(this.$route.params.id)
    },
    homeworkDate() {
      return this.order.homework?.deadlineAt
        ? Moment(this.order.homework?.deadlineAt).format('DD.MM.YYYY')
        : null
    },
    homeworkTime() {
      const currentTimezoneOffset = new Date().getTimezoneOffset() / 60
      const offset = MSK + currentTimezoneOffset ?? 0 // MSK
      const datetime = this.order.homework?.deadlineAt
        ? Moment(this.order.homework?.deadlineAt).add(offset, 'hours')
        : null
      return datetime ? `${datetime.format('HH:mm')}` : null
    },
    homeworkStatusName() {
      return this.order?.homework?.status?.name?.toLowerCase() ?? ''
    }
  },
  mounted() {
    if (this.order?.homework?.documents?.length) {
      for (const file of this.order.homework.documents) {
        if (file.typeId === 1 && file.roleId === 3) {
          this.homeworkFiles.push(file)
        } else if (file.typeId === 2 && file.roleId === 3) {
          this.additionalMaterials.push(file)
        } else if ([1, 2].includes(file.roleId)) {
          this.studentFiles.push(file)
        }
      }
    }
    if (this.order?.homework?.studentComment) {
      this.studentComment = this.order?.homework?.studentComment
    }
  },
  methods: {
    close() {
      this.$store.dispatch('closeModal', {
        success: true,
        result: false
      })
    },
    async downloadHomeworkFile(file) {
      try {
        this.loading = true
        const resp = await homeworkApi.getFile(file.id)
        if (resp?.status === 200 && resp?.data) {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style = 'display:none'
          const url = window.URL.createObjectURL(new Blob([resp.data]))
          a.href = url
          a.download = file.name
          a.click()
          window.URL.revokeObjectURL(url)
          a.remove()
        }
      } catch (error) {
        console.warn(error) // eslint-disable-line
        await this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка', message: 'Ошибка загрузки файла с сервера!' }
        ])
      } finally {
        this.loading = false
      }
    },
    printFileName(name) {
      const text = name.substr(0, name.lastIndexOf('.'))
      return text.length > 35 ? text.substring(0, 35) + '...' : text
    },
    printFileExtension(name) {
      return name.match(/\.([^.]+)$/)?.[1]
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.modal {
  &__homework {
    position: relative;
    width: 48rem;
    min-height: 36.5rem;
    padding: 2rem 1.5rem;
    &__loading {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 32rem;
    }
    &__close {
      position: absolute;
      top: -3rem;
      right: 0;
      display: flex;
      align-items: center;
      padding: 0.438rem 0.75rem 0.438rem 0.5rem;
      background-color: #d7eed5;
      border-radius: 0.781rem;
      cursor: pointer;
      span {
        margin: 0 0 0 0.5rem;
        font-weight: 700;
        font-size: 0.75rem;
        color: $turquoise-green;
        text-transform: uppercase;
      }
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 2rem;
      &_flex {
        display: flex;
        align-items: center;
      }
      &_status {
        margin: 0 0 0 0.5rem;
        font-size: 0.875rem;
        font-weight: 500;
        color: $dark-greenish-yellow-green;
      }
      &_deadline {
        margin: 0 2rem 0 0;
        font-size: 0.875rem;
        font-weight: 400;
        color: $pearl-light-gray;
      }
      &_text {
        margin: 0 1rem 0 0.5rem;
        font-size: 0.688rem;
        font-weight: 500;
        color: $dark-greenish-yellow-green;
        &:last-child {
          margin: 0 0 0 0.5rem;
        }
      }
    }
    &__textarea {
      margin: 0 0 2rem;
      p {
        font-size: 0.875rem;
        font-weight: 400;
        color: $dark-greenish-yellow-green;
      }
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      h3 {
        margin: 0 0 1.5rem;
        font-size: 0.875rem;
        font-weight: 500;
      }
      &__subtitle {
        margin: 0 0 1rem;
        font-size: 0.75rem;
        font-weight: 500;
      }
      &__infoText {
        font-size: 0.625rem;
        font-weight: 400;
      }
    }
    &__files {
      display: flex;
      justify-content: space-between;
      margin: 0 0 3rem;
      &_wrapper {
        width: 45%;
      }
      &__file {
        display: flex;
        align-items: center;
        padding: 0.75rem 0;
        border-bottom: 1px dashed #d9d9d9;
        &_icon {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 0.75rem 0 0;
          span {
            position: absolute;
            text-transform: uppercase;
            font-size: 0.55rem;
            font-weight: 700;
          }
        }
        &_text {
          font-size: 0.75rem;
          font-weight: 400;
          color: $dark-greenish-yellow-green;
        }
        &_close {
          margin: 0 0.25rem 0 auto;
          cursor: pointer;
        }
      }
      &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 5.25rem;
        margin: 1rem 0 1.5rem;
        background: rgba(217, 217, 217, 0.25);
        border-radius: 0.625rem;
        cursor: pointer;
        &__wrapper {
          display: flex;
          align-items: center;
          img {
            margin: 0 1.25rem 0 0;
          }
          span {
            font-weight: 400;
            font-size: 0.75rem;
            color: $pearl-light-gray;
          }
        }
        &__input {
          width: 0;
          height: 0;
        }
      }
    }
    &__date {
      &_button {
        width: 12.625rem !important;
        margin: 1.5rem 0 0;
        padding: 1rem !important;
        font-size: 1rem !important;
        text-transform: none !important;
      }
    }
    &__answer {
      width: 55%;
    }
  }
}
</style>
