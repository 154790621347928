<template>
  <modal-wrapper :cancellable="false" type="promo">
    <button :class="$style.button" @click="close">
      <img
        src="@/assets/icons/close.svg" alt="X"/>
    </button>
    <div :class="$style.modal" >
      <div :class="$style.modal__header">Промокод</div>
      <div :class="$style.modal__content">
        <div :class="$style.modal__content__margin">
          <p :class="$style.modal__content__p">Название</p>
          <CustomInput :class="[$style.modal__content__border, {[$style.modal__content__border__error]: errors.name, [$style.modal__content__border__normal]: !errors.name}]" v-model="name"/>
        </div>
        <div :class="[$style.modal__content__margin, $style.modal__content__flex, $style.modal__content__justify]">
          <div :class="$style.modal__content__double">
            <p :class="$style.modal__content__p">Действителен с</p>
            <date-picker
              v-model="start"
              valueType="format"
              type="datetime"
              :lang="lang"
              placeholer="Дата"
              :class="$style.modal__content__border"
              style="width: 100%;"
            />
            <p v-if="errors.start" style="font-size: 10px;color: red;position: absolute;">Введите значение</p>
          </div>
          <div :class="$style.modal__content__double">
            <p :class="$style.modal__content__p">Действителен до</p>
            <date-picker
              v-model="end"
              :lang="lang"
              valueType="format"
              type="datetime"
              placeholer="Дата"
              :class="$style.modal__content__border"
              style="width: 100%;"
            />
            <p v-if="errors.end" style="font-size: 10px;color: red;position: absolute;">Введите значение</p>
          </div>
        </div>
        <div :class="[$style.modal__content__margin, $style.modal__content__flex, $style.modal__content__justify]">
          <div :class="$style.modal__content__double">
            <p :class="$style.modal__content__p">Лимит</p>
            <InputNumber :maxlength="7" v-model="limit" :class="[$style.modal__content__border, $style.modal__content__border__height]"/>
          </div>
          <div :class="$style.modal__content__double">
            <p :class="$style.modal__content__p">Лимит для пользователя</p>
            <InputNumber :maxlength="7" v-model="userLimit" :class="[$style.modal__content__border, $style.modal__content__border__height]"/>
          </div>
        </div>
        <div :class="$style.modal__content__margin">
          <div :class="[$style.modal__content__margin__small, $style.modal__content__justify, $style.modal__content__flex, $style.modal__content__bottom]">
            <div :class="$style.modal__content__p" style="width: 227px;">Пакет</div>
            <div :class="$style.modal__content__p" style="width: 138px;">Размер скидки</div>
            <div :class="$style.modal__content__p" style="width: 121px;">Тип</div>
          </div>
          <div v-for="(item, index) in items" :key="index" :class="[$style.modal__content__margin__small, $style.modal__content__bottom]">
            <div :class="[$style.modal__content__justify, $style.modal__content__flex]">
              <div style="width: 227px;">
                <DefaultSelect
                  v-model="item.tariff"
                  :items="getTariffs"
                  placeholder="Выбрать тариф"
                  style="width: 227px;"
                  :newTable="true"
                  :class="[$style.modal__content__border, $style.modal__content__border__height]"
                  @input="(val) => item.tariff = val.id"
                />
              </div>
              <div style="width: 138px;">
                <InputNumber :maxlength="7" v-model="item.amount" :class="[$style.modal__content__border, $style.modal__content__border__height]"/>
              </div>
              <div :class="$style.modal__content__border__height" style="width: 73px;">
                <DefaultSelect
                  v-model="item.type"
                  :items="types"
                  placeholder="Тип"
                  :newTable="true"
                  style="width: 73px;"
                  :class="[$style.modal__content__border, $style.modal__content__border__height]"
                  @input="(val) => item.type = val.id"/>
              </div>
              <div :class="[$style.modal__content__flex, $style.modal__content__justify__center, $style.modal__content__cursor__pointer]" style="width: 24px;" @click="deleteItem(item)"><img src="../../assets/icons/trash.svg" alt="trash"></div>
            </div>
            <p v-if="Object.values(errors.index).includes(String(index))" style="font-size: 10px; color: red;">Выберите и введите все значения</p>
          </div>
          <CustomButton type="transparent" @click="addItem">Добавить</CustomButton>
        </div>

        <div :class="$style.modal__content__margin">
          <p :class="$style.modal__content__p">Комментарий</p>
          <CustomTextarea :maxlength="200" :class="$style.modal__content__border" v-model="description"/>
        </div>
        <div :class="$style.modal__content__flex">
          <CustomButton type="transparent" style="margin-right: 20px;" @click="addPromo">Сохранить</CustomButton>
          <CustomButton type="transparent" @click="close">Отмена</CustomButton>
        </div>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/basic/ModalWrapper.vue'
import CustomInput from '@/basic/Input.vue'
import CustomTextarea from '@/basic/CustomTextarea.vue'
import CustomButton from '@/basic/Button.vue'
import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'
import DefaultSelect from '@/basic/DefaultSelect.vue'
import InputNumber from '@/basic/InputNumber.vue'
import promos from '@/api/promos'

import 'vue2-datepicker/index.css'

export default {
  name: 'Promo',
  components: { InputNumber, DefaultSelect, CustomButton, CustomTextarea, CustomInput, ModalWrapper, DatePicker },
  data() {
    return {
      lang: ru,
      start: '',
      end: '',
      name: '',
      userLimit: null,
      limit: null,
      description: '',
      items: [
        {
          tariff: null,
          amount: null,
          type: null
        }
      ],
      types: [{ id: 1, name: 'руб' }, { id: 2, name: '%' }],
      getTariffs: [],
      errors: {
        name: false,
        start: false,
        end: false,
        index: []
      }
    }
  },
  watch: {
    items() {
      const selectItems = this.items.map( el => {return { id: el.tariff }})
      for (let i = 0; i < this.getTariffs.length; i++) {
        const obj1 = this.getTariffs[i]

        for (let j = 0; j < selectItems.length; j++) {
          const obj2 = selectItems[j]
          if (obj1.id === obj2.id) {
            obj1.disabled = true
            break
          } else {
            obj1.disabled = false
            break
          }
        }
      }
    },
    start() {
      if (this.start && this.start.length > 0) {
        this.errors.start = false
      }
    },
    end() {
      if (this.end && this.end.length > 0) {
        this.errors.end = false
      }
    }
  },
  mounted() {
    this.items = [{tariff: null, amount: null, type: null}]
    this.getTariffs = this.$store.getters['constants']?.tariffs.filter(tariff => tariff.typeId !== 2)
  },
  methods: {
    close() {
      this.$store.dispatch('closeModal',{
        success: true,
        result: false
      })
    },
    addItem() {
      this.items.push({tariff: null, amount: null, type: null})
    },
    deleteItem(item) {
      this.items = this.items.filter(obj => obj !== item)
    },
    splitArray(array) {
      const groupedArrays = {}
      array.forEach(obj => {
        const key = obj.amount + '-' + obj.type
        if (!groupedArrays[key]) {
          groupedArrays[key] =[]
        }
        groupedArrays[key].push(obj)
      })
      return Object.values(groupedArrays)
    },
    async addPromo() {
      if (this.name.length < 5) {
        this.errors.name = true
        return
      } else {
        this.errors.name = false
      }
      if (!this.start || this.start.length < 1) {
        this.errors.start = true
        return
      } else {
        this.errors.start = false
      }
      if (!this.end || this.end.length < 1) {
        this.errors.end = true
        return
      } else {
        this.errors.end = false
      }

      let promoCodes = this.splitArray(this.items).map( promoCode => ({
        code: this.name,
        active: true,
        start: this.start,
        limit: +this.limit === 0 ? null : +this.limit,
        userLimit: +this.userLimit === 0 ? null : +this.userLimit,
        end: this.end,
        description: this.description,
        type: [...new Set(promoCode.map(el => el.type))],
        amount: [...new Set(promoCode.map(el => el.amount))],
        tariffIds: promoCode.map(tariffId => tariffId.tariff)
      }))
      for (let promoCode in promoCodes) {
        for (let el in promoCodes[promoCode].amount) {
          if (!promoCodes[promoCode].amount[el]) {
            this.errors.index.push(promoCode)
            return
          } else {
            this.errors.index = this.errors.index.filter( el => el !== promoCode)
          }
        }
        for (let el in promoCodes[promoCode].type) {
          if (!promoCodes[promoCode].type[el]) {
            this.errors.index.push(promoCode)
            return
          } else {
            this.errors.index = this.errors.index.filter( el => el !== promoCode)
          }
        }
        for (let el in promoCodes[promoCode].tariffIds) {
          if (!promoCodes[promoCode].tariffIds[el]) {
            this.errors.index.push(promoCode)
            return
          } else {
            this.errors.index = this.errors.index.filter( el => el !== promoCode)
          }
        }
      }
      for (let promoCode of promoCodes) {
        const type = promoCode.type[0]
        if (type === 1) {
          promoCode.amount = Number(promoCode.amount[0])
          delete promoCode.type
        }
        if (type === 2 && promoCode.amount) {
          promoCode.discount = Number(promoCode.amount[0])
          if (promoCode.discount > 100) {
            return
          }
          delete promoCode.amount
          delete promoCode.type
        }
      }

      try {
        const { data } = await promos.addPromo({ promocodes: promoCodes })
        if (data.success) {
          this.close()
          window.location.reload()
        } else {
          await this.$store.dispatch('openModal', [
            'Alert',
            { title: 'Ошибка!', message: data.message }
          ])
        }
      } catch (e) {
        await this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка!', message: e.message }
        ])
      }
    }
  }
}
</script>

<style lang="scss">
.mx-datepicker-popup {
  z-index: 9999!important;
}
.mx-input {
  border-radius: 0;
  border-color: rgba(188, 188, 188, 1);
  &__error {
    border-color: #f85e0f;
  }
  &:hover {
    border-color: rgba(188, 188, 188, 1);
  }
}
</style>

<style lang="scss" module>
.button {
  position: relative;
  bottom: 3.75rem;
  left: 96%;
  cursor: pointer;
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.modal {
  width: 36.375rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
  &__header {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.172rem;
    margin-bottom: 2.25rem;
  }
  &__content {
    &__cursor {
      &__pointer {
        cursor: pointer;
      }
    }
    &__bottom {
      border-bottom: 0.063rem solid rgba(188, 188, 188, 1);
    }
    &__double {
      width: 15.938rem;
    }
    &__border {
      border-radius: 0;
      box-shadow:none;
      border-color: rgba(188, 188, 188, 1);
      &__normal {
        border-color: rgba(188, 188, 188, 1);
      }
      &__error {
        border-color: #f85e0f;
      }
      &__height {
        height: 2.438rem;
        display: flex;
        align-items: center;
      }
    }
    &__p {
      font-size: 0.813rem;
      font-weight: 600;
      line-height: 0.952rem;
      font-family: Roboto, sans-serif;
      color: rgba(151, 150, 150, 1);
      margin-bottom: 0.5rem;
    }
    &__margin {
      margin-bottom: 2.25rem;
      &__small {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
      }
    }
    &__flex {
      display: flex;
    }
    &__justify {
      justify-content: space-between;
      align-items: center;
      &__center {
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
