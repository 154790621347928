export default {
  state: {
    notice: null,
    noticeData: null,
    noticeType: 'secondary'
  },
  mutations: {
    setNotice(state, notice) {
      state.notice = notice
    },
    setNoticeData(state, data) {
      state.noticeData = data
    },
    setNoticeType(state, type) {
      state.noticeType = type
    }
  },
  getters: {
    notice: ({ notice }) => notice,
    noticeData: ({ noticeData }) => noticeData,
    noticeType: ({ noticeType }) => noticeType
  }
}
