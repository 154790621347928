<template>
  <textarea
    :class="[$style.textarea, $style[type], { [$style.textarea_error]: error }]"
    :value="value"
    :maxlength="maxlength"
    :readonly="readonly"
    :placeholder="placeholder"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  name: 'CustomTextarea',
  props: {
    value: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 100
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.textarea {
  width: 100%;
  padding: 0.75rem 0.625rem;
  background: $white;
  border: 1px solid $platinum;
  border-radius: 0.625rem;
  box-shadow: inset 2px 2px 0px $platinum;
  color: $wet-asphalt;
  font-size: 0.875rem;
  resize: none;
  transition: all 0.3s ease;

  &.secondary {
    box-shadow: none;
    border: 1px solid $wet-asphalt;
  }

  &::placeholder {
    font-size: 0.875rem;
    color: $wet-asphalt;
  }

  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.25rem 0.25rem $light-gray;
    border: solid 0.188rem transparent;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0.25rem 0.25rem $dark-green-sea;
    border: solid 0.188rem transparent;
    border-radius: 1rem;
  }

  &_error {
    box-shadow: inset 2px 2px 0px red !important;
    border: 1px solid red !important;

    &.secondary {
      box-shadow: none !important;
      border-color: red;
    }
  }
}
</style>
