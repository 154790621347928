'use strict'

import axios from 'axios'
import Constants from '@/application/constants'
//import store from '@/store'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: Constants.API_URL,
  // timeout: 60 * 1000, // Timeout
  withCredentials: false // Check cross-site Access-Control
}
axios.defaults.headers['x-access-token'] = sessionStorage.getItem('x-access-token')
const httpServicePlugin = axios.create(config)

httpServicePlugin.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
httpServicePlugin.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error)
  }
)

httpServicePlugin.install = async function (Vue) {
  Vue.axios = httpServicePlugin
  Vue.prototype.axios = httpServicePlugin

  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return httpServicePlugin
      }
    },
    $axios: {
      get() {
        return httpServicePlugin
      }
    }
  })
}

export default httpServicePlugin
