<template>
  <div :class="$style.modal__wrapper">
    <div :class="$style.modal__bg" @click="closeHandler" />

    <img
      v-if="cancellable"
      :class="$style.modal__close"
      src="@/assets/icons/close.svg"
      alt="close"
      @click="closeHandler"
    />

    <div :class="$style.modal__container" @click.self="closeHandler">
      <div :class="[$style.separator, $style[type]]" />

      <div v-if="loading" :class="$style.modal__loaderIndicator">
        <Loading />
      </div>

      <div
        v-else
        :class="[
          $style.modal__window,
          { [$style.modal__window_loading]: loading },
          $style[type]
        ]"
      >
        <h2 v-if="title" :class="$style.modal__title">{{ title }}</h2>
        <slot />
      </div>

      <div :class="[$style.separator, $style[type]]" />
    </div>
  </div>
</template>

<script>
import Loading from './Loading.vue'
export default {
  name: 'ModalWrapper',
  components: { Loading },
  props: {
    title: {
      type: String,
      default: '',
      require: false
    },
    cancellable: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: ''
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loading() {
      return this.$store.getters['modalLoading']
    }
  },
  mounted() {
    if (this.hidden) {
      document.body.style.overflow = 'hidden'
    }
  },
  beforeDestroy() {
    if (this.hidden) {
      document.body.style.overflow = 'auto'
    }
  },
  methods: {
    closeHandler() {
      if (this.cancellable) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors';
@import '@/assets/styles/breakpoints';

.modal {
  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9000;
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $transparent-gray;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @include for-desktop {
      &::-webkit-scrollbar {
        width: 10px;
        background-color: $light-gray;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $dark-green-sea;
      }

      &::-webkit-scrollbar-track {
        background-color: $light-gray;
        box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.2);
      }
    }

    .separator {
      height: 9.375rem;
      flex-shrink: 0;

      &.homework {
        height: 4rem;
      }
    }
  }

  &__window {
    padding-top: 1.25rem;
    background-color: $white;

    @include for-mobile {
      width: 96%;
    }

    &.form {
      padding-top: 0;
      border-radius: 0.625rem;
    }
    &.promo {
      padding-top: 0;
      border-radius: 0.938rem;
    }
    &.homework {
      padding: 0;
      border-radius: 0.938rem;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    }
    &.comment {
      padding-top: 0;
      border-radius: 1rem;
    }
  }

  &__loaderIndicator {
    padding: 1.125rem 2rem;
    background: white;
  }

  &__title {
    padding: 0 1.25rem;
    font-size: 2rem;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    padding: 1rem;
    cursor: pointer;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}
</style>
