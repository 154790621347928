<template>
  <div
    :class="[
      $style.number,
      $style[type],
      { [$style.number__input_disabled]: disabled },
      { [$style.number_error]: error }
    ]"
  >
    <input
      v-mask="mask || nomask"
      :value="value"
      :class="$style.number__input"
      type="text"
      :disabled="disabled"
      :placeholder="placeholder"
      :maxlength="maxlength"
      @input="$emit('input', $event.target.value)"
    />
    <div :class="$style.number__lable">{{ label }}</div>

    <transition name="inputTopEnter" mode="out-in">
      <div v-if="errorText && error && !value" key="1" :class="$style.number__message">
        Заполните поле
      </div>
      <div v-if="errorText && error && value" key="2" :class="$style.number__message">
        Поле заполнено некорректно
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 1
    },
    mask: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    nomask() {
      return '#'.repeat(this.maxlength)
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.number {
  position: relative;
  background: $white;
  border: 1px solid transparent;
  box-shadow: inset 2px 2px 0px $platinum;

  &.secondary {
    border: 1px solid $platinum;
    box-shadow: none;
  }
  &.container {
    border: 0.063rem solid $input-border;
    box-shadow: none;
    height: 2.125rem;
    border-radius: 0.25rem;
    input {
      padding: 0.375rem!important;
      font-size: 0.875rem!important;
    }
  }
  &__input {
    width: 100%;
    padding: 0.8rem;

    color: $wet-asphalt;
    font-size: 1.25rem;
    text-align: center;
    font-weight: 500;
    transition: border 0.3s ease;

    &::placeholder {
      font-size: 1.25rem;
      font-weight: 500;
      color: $pearl-light-gray;
      text-align: center;
    }
  }

  &_disabled {
    background: $gainsborough !important;
    pointer-events: none;
  }

  &_error {
    box-shadow: inset 2px 2px 0px $error !important;
    border: 1px solid $error !important;

    &.secondary {
      border: 1px solid $error !important;
      box-shadow: none !important;
    }
  }

  &__lable {
    position: absolute;
    top: -1rem;
    font-weight: 300;
    font-size: 0.5rem;
    color: $green-moss;
  }

  &__message {
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 9rem;
    font-size: 0.625rem;
    color: $error-light;
  }
}
</style>
