import userApi from '@/api/users'

export default {
  state: {
    files: []
  },

  mutations: {
    setPedagogFiles(state, files) {
      state.files = files
    }
  },

  actions: {
    async getPedagogFiles({ commit }, pedagogId) {
      try {
        const { data } = await userApi.getPedagogFiles(pedagogId)

        if (data?.success) {
          commit('setPedagogFiles', data.result)
        }
      } catch (error) {
        console.warn(error)
      }
    }
  },

  getters: {
    pedagogFiles: ({ files }) => files
  }
}
