import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import orders from './modules/orders'
import chat from './modules/chat'
import parents from './modules/parents'
import teachers from './modules/teachers'
import logopedists from './modules/logopedists'
import promos from './modules/promos'
import preschoolTeacher from './modules/preschoolTeacher'
import elementaryTeachers from './modules/elementaryTeachers'
import notifications from './modules/notifications'
import files from './modules/files'
import notice from './modules/notice'
import modals from './modules/modals'
import constants from './modules/constants'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    orders,
    chat,
    parents,
    teachers,
    logopedists,
    preschoolTeacher,
    elementaryTeachers,
    notifications,
    files,
    notice,
    modals,
    constants,
    promos
  }
})
