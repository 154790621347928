export default {
  state: {
    modal: null,
    service: null,
    loading: false,
    promiseController: null,
    params: null
  },

  mutations: {
    setModal: (state, name) => (state.modal = name),
    setService: (state, service) => (state.service = service),
    setPromiseController: (state, controller) => (state.promiseController = controller),
    setParams: (state, params) => (state.params = params),

    setModalLoading: (state, loading) => (state.loading = loading)
  },

  getters: {
    modal: (state) => state.modal,
    modalService: (state) => state.service,
    modalLoading: (state) => state.loading,
    modalParams: (state) => state.params
  },

  actions: {
    openModal({ commit }, [modalType, params]) {
      return new Promise((resolve, reject) => {
        commit('setPromiseController', { resolve, reject })
        commit('setModal', modalType)
        commit('setService', params)
      })
    },
    closeModal({ commit, state }, { success, result, params = null, error } = {}) {
      if (success) {
        state.promiseController?.resolve(result)
      } else {
        state.promiseController?.reject(error)
      }

      commit('setModal', null)
      commit('setService', null)
      commit('setParams', params)
      commit('setPromiseController', null)
    }
  }
}
