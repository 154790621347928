import userApi from '@/api/users'
import orderApi from '@/api/orders'

export default {
  state: {
    teacherDetails: {},
    teacherOrders: []
  },

  mutations: {
    setTeacherDetails(state, teacherDetails) {
      state.teacherDetails = teacherDetails
    },
    setTeacherOrders(state, orders) {
      state.teacherOrders = orders
    }
  },

  actions: {
    async getTeacher({ commit }, pedagogId) {
      try {
        const { data } = await userApi.getTeacher({
          filter: {
            id: pedagogId
          }
        })

        if (data?.success) {
          commit('setTeacherDetails', data.result.rows[0])
          return data.result.rows[0]
        }
      } catch (error) {
        console.warn(error)
      }
    },

    async getTeacherOrders({ commit }, filter) {
      try {
        const { data } = await orderApi.getOrders(filter)

        if (data?.success) {
          commit('setTeacherOrders', data.result)
        }
      } catch (error) {
        console.warn(error)
      }
    }
  },

  getters: {
    teacherDetails: ({ teacherDetails }) => teacherDetails,
    teacherOrders: ({ teacherOrders }) => teacherOrders
  }
}
